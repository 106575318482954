/* eslint-disable react/prop-types */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { commonStyle } from '../../styles/commonStyle'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Divider,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  Box,
  FormControl,
  FormGroup,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  TextField,
  Button,
  CircularProgress,
  CardActions
} from '@material-ui/core'
import {
  OpenToast,
  setSlotName,
  updateNewRegistrySlot,
  updateRegistrySlot,
  ToggleSlotDialogStatus,
  ToggleIsChange
} from '../../modules/slotSlice'
import { CustomTargetingAccordion } from '../sites/CustomTargetingAccordion'
import { fetchSlotTypes } from '../../modules/slotTypesSlice'
import { fetchSlotLocations } from '../../modules/slotLocationSlice'
import { ToggleSwitch } from '../ui/ToggleSwitch'
import { hasRole } from '../../utilities/helperFunctions'
import Alert from '@material-ui/lab/Alert'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { number as yupNumber, object as yupobject } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const swal = withReactContent(Swal)

const useStyle = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 170
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  itemRight: { textAlign: 'right', float: 'right' },
  itemCenter: { textAlign: 'Center' },
  Targetings: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  contant: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(2)
  },
  ResponsiveContaner: { width: '98%' },
  action: {
    position: 'absolute',
    bottom: theme.spacing(0),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    background: theme.palette.background.paper,
    width: '98%'
  },
  box: { textAlign: 'right', width: '100%', padding: '8px 16px' }

}))

export default function Slot ({ data, isSingleton = true }) {
  const slot = JSON.parse(JSON.stringify(data))
  const classes = useStyle()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.authReducer)
  const { site, siteIVR } = useSelector(state => state.sitesSlice)
  const { registry, adUnitDictionary, loading, isChanged } = useSelector(
    state => state.slotSlice
  )
  const { isViewRefresh } = useSelector(state => state.registrySlice)
  const { slotTypes } = useSelector(state => state.slotTypeSlice)
  const { locations } = useSelector(state => state.slotLocationSlice)
  const [currentRegistry, setCurrentRegistry] = useState([])
  const [originalSlot, setOriginalSlot] = useState([])
  const [newSlot, setNewSlot] = useState([])
  const [adUnitSizesUI, setAdUnitSizesUI] = useState([])
  const [isSafeframeConfigEnable, setIsSafeframeConfigEnable] = useState(false)
  const [isViewRefreshSlot, setIsViewRefreshSlot] = useState(false)
  const [slotLocations, setSlotLocations] = useState([])
  const [viewports, setViewports] = useState([])

  const validateAdUnitSizes = () => {
    const values = getValues()
    const msg = 'Ad Sizes are required.'
    if (!newSlot.isFluid) {
      if (
        typeof values.adUnitSizes === 'undefined' ||
        values.adUnitSizes === undefined
      ) {
        return msg
      } else if (typeof values.adUnitSizes === 'object') {
        return values.adUnitSizes.filter(v => Boolean(v)).length >= 1 || msg
      }
    } else {
      return true
    }
  }

  const validateFluid = () => {
    const msg = 'Ad Sizes/Fluid is required for Fluid Slot.'
    if (newSlot.isFluid) {
      const values = getValues()
      return (
        viewports.filter(viewport => Boolean(viewport.isFluid))?.length ===
        viewports.length ||
        values.adUnitSizes.filter(v => Boolean(v)).length >= 1 ||
        msg
      )
    } else {
      return true
    }
  }

  const validationSchema = yupobject().shape({
    slotViewRefreshCount: yupNumber()
      .typeError('Count must be a number')
      .required('Required')
      .min(5, 'Min value 5'),
    slotViewRefreshInterval: yupNumber()
      .typeError('Interval must be a number')
      .required('Required')
      .min(35, 'Min value 35')
  })

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    unregister,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const UpdateSlot = () => {
    const currentSlots = currentRegistry.slots?.slice()
    console.log('currentSlots', currentSlots)
    // find the index of object from array that you want to update
    const currentSlotIndex = currentRegistry.slots?.findIndex(
      slot => slot._id === newSlot._id || slot.name === newSlot.name
    )
    // make new object of updated object.
    currentSlots[currentSlotIndex] = newSlot
    currentSlots[currentSlotIndex].viewportMappings = getViewportMappings(
      newSlot?.viewportMappings
    )
    // const updatedRegistry = {
    //   ...currentRegistry,
    //   isSingleton: isSingleton,
    //   slots: currentSlots
    // }
    let updatedRegistry = null
    console.log('[OG Slot] ========== Is Site using WOPR?', site.useWOPR)
    console.log('[OG Slot] ========== Is Registry using a template?', currentRegistry.template)
    if (site.useWOPR) {
      updatedRegistry = {
        ...currentRegistry,
        name: currentSlots.length === 1 ? currentSlots[0].adUnitPath.replace(/\//g, '_')?.toUpperCase() : currentSlots.length > 1 ? currentSlots[0].adUnitPath.replace(/\//g, '_')?.toUpperCase() : '',
        isSingleton,
        slots: currentSlots
      }
    } else {
      updatedRegistry = {
        ...currentRegistry,
        isSingleton,
        slots: currentSlots
      }
    }
    console.log('Update Registtry', updatedRegistry)
    if (currentRegistry._id === undefined) {
      dispatch(updateNewRegistrySlot(updatedRegistry))
    } else {
      dispatch(updateRegistrySlot(updatedRegistry))
    }
  }

  const handleSlotLeafAdUnitChange = (event, index) => {
    // #region  set the slot's leaf adUnit when the UI changes
    const rootAdUnit = adUnitDictionary[site.rootAdUnitId]
    let adUnitIds = []
    if (newSlot.adUnitPath) {
      let adUnit = rootAdUnit
      const adUnitPathNames = newSlot.adUnitPath.split('/')
      for (let index = 1; index < adUnitPathNames.length; index++) {
        if (!adUnit.hasChildren) {
          break
        }
        adUnit = adUnit.children.find(
          child => child.name === adUnitPathNames[index]
        )
        if (!adUnit) {
          break
        }
        adUnitIds.push(adUnit._id)
      }

      if (adUnitIds.length !== adUnitPathNames.length - 1) {
        // this full adUnitPath could not be found because some part of it is inactive, so notify user
        dispatch(
          OpenToast({
            message: `${newSlot.name} is using the following Ad Unit, which is inactive.  ${newSlot.adUnitPath}`,
            variant: 'error'
          })
        )
      }

      const RsltAdUnitIds =
        adUnitIds.length > 0 ? adUnitIds.splice(0, index) : adUnitIds
      adUnitIds = RsltAdUnitIds
      if (event.target.value !== -1) {
        adUnitIds.push(event.target.value)
      }
    }
    let newAdUnitId = rootAdUnit._id
    let adUnitPath = rootAdUnit.name
    adUnitIds.forEach(adUnitId => {
      const adUnit = adUnitDictionary[adUnitId]
      if (adUnit) {
        // ignore trailing nulls
        adUnitPath += '/' + adUnit.name
        newAdUnitId = adUnit._id
      }
    })

    // set adsizes based on chosen adUnit
    const newAdUnit = adUnitDictionary[newAdUnitId]
    const adUnitSizes = newAdUnit.adUnitSizes
      .slice()
      .map(item => ({
        ...item,
        checked: false,
        name: item.width + 'x' + item.height
      }))
      .map(obj => adUnitSizesUI.find(o => o.name === obj.name) || obj)

    const adSizes = []
    const invalidAdSizes = []
    newSlot.adSizes.forEach(adSize => {
      let adSizeFound = false
      // If size is fluid (0x0), allow it through.
      for (let i = 0, len = adUnitSizes.length; i < len; i++) {
        // look for first matching adUnitSize
        const adUnitSize = adUnitSizes[i]
        if (
          adUnitSize.width === adSize.width &&
          adUnitSize.height === adSize.height
        ) {
          adSizes.push(adSize)
          adSizeFound = true
          break
        }
      }

      if (!adSizeFound && adSize.width !== 0) {
        invalidAdSizes.push(adSize)
      }
    })

    setAdUnitSizesUI(adUnitSizes)

    const checkedAdUnitSizes = adUnitSizes
      .filter(item => item.checked === true)
      .map(item => ({
        ...item,
        checked: false
      }))
    const viewportMappings = viewports.map(viewport => ({
      ...viewport,
      adSizes: checkedAdUnitSizes
    }))
    setViewports(viewportMappings)
    setNewSlot({
      ...newSlot,
      adUnitPath,
      adSizes,
      viewportMappings
    })

    if (invalidAdSizes.length > 0) {
      const invalidAdSizesDisplay = invalidAdSizes
        .map(function (invalidAdSize) {
          return invalidAdSize.width + 'x' + invalidAdSize.height
        })
        .join(', ')
      dispatch(
        OpenToast({
          message: `invalid AdSize ${invalidAdSizes.length > 1 ? 's' : ''
            } for ${newSlot.name}: ${invalidAdSizesDisplay} - ${invalidAdSizes.length > 1 ? 'they have' : 'it has'
            }  been removed.`,
          variant: 'error'
        })
      )
    }
  }

  const adUnitFilds = () => {
    const rootAdUnit = adUnitDictionary[site.rootAdUnitId]
    const adUnitIds = []
    if (newSlot.adUnitPath) {
      let adUnit = rootAdUnit
      const adUnitPathNames = newSlot.adUnitPath.split('/')
      for (let index = 1; index < adUnitPathNames.length; index++) {
        if (!adUnit.hasChildren) {
          break
        }
        adUnit = adUnit.children.find(
          child => child.name === adUnitPathNames[index]
        )
        if (!adUnit) {
          break
        }
        adUnitIds.push(adUnit._id)
      }

      return (
        <Grid container row='true' className={commonClasses.m_1}>
          <Grid item xs={2} sm={2}>
            <TextField
              style={{ width: '85%' }}
              disabled
              label='Ad Unit'
              name='adUnit'
              margin='dense'
              id={currentRegistry.name}
              value={newSlot.adUnitPath.split('/')[0]}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds[0] && adUnitDictionary[site.rootAdUnitId].children ? (
              <span> / </span>
            ) : (
              <span className={commonClasses.pr_1} />
            )}
            <Select
              style={{ width: '85%', marginTop: '16px' }}
              id='adUnitId-0'
              value={adUnitIds[0] ? adUnitIds[0] : -1}
              name='adUnitId-0'
              onChange={e => handleSlotLeafAdUnitChange(e, 0)}
            >
              <MenuItem key={-1} value={-1} />
              {adUnitDictionary[site.rootAdUnitId].children.map(adUnit => (
                <MenuItem key={adUnit.name} value={adUnit._id}>
                  {adUnit.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds[1] ? <span> / </span> : <span />}
            {adUnitIds[0] && adUnitDictionary[adUnitIds[0]].children ? (
              <Select
                style={{ width: '85%', marginTop: '16px' }}
                id='adUnitId-1'
                value={adUnitIds[1] ? adUnitIds[1] : -1}
                name='adUnitId-1'
                onChange={e => handleSlotLeafAdUnitChange(e, 1)}
              >
                <MenuItem key={-1} value={-1} />
                {adUnitDictionary[adUnitIds[0]].children.map(adUnit => (
                  <MenuItem key={adUnit.name} value={adUnit._id}>
                    {adUnit.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <span />
            )}
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds[2] ? <span> / </span> : <span />}
            {adUnitIds[1] && adUnitDictionary[adUnitIds[1]].children ? (
              <Select
                style={{ width: '85%', marginTop: '16px' }}
                id='adUnitId-2'
                value={adUnitIds[2] ? adUnitIds[2] : -1}
                name='adUnitId-2'
                onChange={e => handleSlotLeafAdUnitChange(e, 2)}
              >
                <MenuItem key={-1} value={-1} />
                {adUnitDictionary[adUnitIds[1]].children.map(adUnit => (
                  <MenuItem key={adUnit.name} value={adUnit._id}>
                    {adUnit.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <span />
            )}
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds[3] ? <span> / </span> : <span />}
            {adUnitIds[2] && adUnitDictionary[adUnitIds[2]].children ? (
              <Select
                style={{ width: '85%', marginTop: '16px' }}
                id='adUnitId-2'
                value={adUnitIds[3] ? adUnitIds[3] : -1}
                name='adUnitId-2'
                onChange={e => handleSlotLeafAdUnitChange(e, 3)}
              >
                <MenuItem key={-1} value={-1} />
                {adUnitDictionary[adUnitIds[2]].children.map(adUnit => (
                  <MenuItem key={adUnit.name} value={adUnit._id}>
                    {adUnit.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <span />
            )}
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds[4] ? (
              <span> / </span>
            ) : (
              <span className={commonClasses.ml_2} />
            )}
            {adUnitIds[3] && adUnitDictionary[adUnitIds[3]].children ? (
              <Select
                style={{ width: '85%', marginTop: '16px' }}
                id='adUnitId-2'
                value={adUnitIds[4] ? adUnitIds[4] : -1}
                name='adUnitId-2'
                onChange={e => handleSlotLeafAdUnitChange(e, 4)}
              >
                <MenuItem key={-1} value={-1} />
                {adUnitDictionary[adUnitIds[3]].children.map(adUnit => (
                  <MenuItem key={adUnit.name} value={adUnit._id}>
                    {adUnit.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <span />
            )}
          </Grid>
        </Grid>
      )
    }
  }

  const toggleInViewRefresh = event => {
    if (event.target.checked) {
      setNewSlot({
        ...newSlot,
        hasInViewRefresh: event.target.checked,
        inViewRefreshCount: siteIVR.DEFAULT_REFRESH_COUNT,
        inViewRefreshInterval: (siteIVR.DEFAULT_REFRESH_MILLISECONDS/1000)
      })
    } else {
      setNewSlot({
        ...newSlot,
        hasInViewRefresh: event.target.checked,
        inViewRefreshCount: 0,
        inViewRefreshInterval: 0
      })
    }
    setIsViewRefreshSlot(event.target.checked)
  }

  const handleInViewRefreshChange = e => {
    switch (e.target.name) {
      case 'slotViewRefreshCount':
        setNewSlot({
          ...newSlot,
          inViewRefreshCount: parseInt(e.target.value)
        })
        setValue('slotViewRefreshCount', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      case 'slotViewRefreshInterval':
        setNewSlot({
          ...newSlot,
          inViewRefreshInterval: parseInt(e.target.value)
        })
        setValue('slotViewRefreshInterval', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      default:
        break
    }
  }

  const handleInheritanceChange = event => {
    setNewSlot({
      ...newSlot,
      isInheritAdUnitFromRegistry: event.target.value === 'true'
    })
  }

  const handleSafeframeConfigChange = e => {
    switch (e.target.name) {
      case 'slotSafeframeConfig':
        setNewSlot({
          ...newSlot,
          safeFrameConfig: e.target.checked
        })
        setIsSafeframeConfigEnable(e.target.checked)
        break
      case 'slotSandbox':
        setNewSlot({
          ...newSlot,
          safeFrameSandboxMode: e.target.checked
        })
        break
      case 'slotAllowOverlayExpansion':
        setNewSlot({
          ...newSlot,
          safeFrameAllowOverlayExpansion: e.target.checked
        })
        break
      case 'slotAllowPushExpansion':
        setNewSlot({
          ...newSlot,
          safeFrameAllowPushExpansion: e.target.checked
        })
        break
      default:
        break
    }
  }

  const toggleIsResponsive = event => {
    setNewSlot({
      ...newSlot,
      isResponsive: event.target.checked
    })
  }

  const toggleIsFluid = event => {
    if (event.target.checked) {
      setNewSlot({
        ...newSlot,
        isFluid: event.target.checked
      })
      unregister('adUnitSizes')
      newSlot.viewportMappings.forEach(viewPort => {
        unregister(viewPort.siteViewportId)
      })
    } else {
      const _viewportMappings = newSlot.viewportMappings.map(viewport => ({
        ...viewport,
        isFluid: false
      }))
      const _viewports = viewports.map(viewport => ({
        ...viewport,
        isFluid: false
      }))
      setViewports(_viewports)
      setNewSlot({
        ...newSlot,
        isFluid: event.target.checked,
        viewportMappings: _viewportMappings
      })
      setValue(
        'adUnitSizes',
        adUnitSizesUI.filter(adUnitSize => adUnitSize.checked),
        { shouldValidate: true }
      )
    }
  }

  const handleSuppressedChange = (event, viewport) => {
    // find the index of object from array that you want to update
    const selectedViewportIndex = viewports.findIndex(
      _viewport => _viewport.siteViewportId === viewport.siteViewportId
    )

    const isFluidChecked = viewports[selectedViewportIndex].isFluid
    const isSuppressedChecked = viewports[selectedViewportIndex].isSuppressed
    // make new object of updated object.
    const updatedViewport = {
      ...viewports[selectedViewportIndex],
      isSuppressed: event.target.checked,
      isFluid: !isFluidChecked ? false : isFluidChecked && isSuppressedChecked
    }

    // make final new array of objects by combining updated object.
    const updatedViewports = [
      ...viewports.slice(0, selectedViewportIndex),
      updatedViewport,
      ...viewports.slice(selectedViewportIndex + 1)
    ]
    setViewports(updatedViewports)
    setNewSlot({
      ...newSlot,
      viewportMappings: updatedViewports
    })
    if (event.target.checked) {
      unregister(viewport.siteViewportId)
    }
  }

  const handleFluidChange = (event, viewport) => {
    // find the index of object from array that you want to update
    const selectedViewportIndex = viewports.findIndex(
      _viewport => _viewport.siteViewportId === viewport.siteViewportId
    )

    const isFluidChecked = viewports[selectedViewportIndex].isFluid
    const isSuppressedChecked = viewports[selectedViewportIndex].isSuppressed
    // make new object of updated object.
    const updatedViewport = {
      ...viewports[selectedViewportIndex],
      isFluid: event.target.checked,
      isSuppressed: !isSuppressedChecked
        ? false
        : isFluidChecked && isSuppressedChecked
    }

    // make final new array of objects by combining updated object.
    const updatedViewports = [
      ...viewports.slice(0, selectedViewportIndex),
      updatedViewport,
      ...viewports.slice(selectedViewportIndex + 1)
    ]
    setViewports(updatedViewports)
    setNewSlot({
      ...newSlot,
      viewportMappings: updatedViewports
    })
  }

  const viewPortHelperText = viewport => {
    if (newSlot.isFluid) {
      if (
        viewport.isFluid ||
        viewport.isSuppressed ||
        viewport.adSizes.filter(v => v.checked).length >= 1
      ) {
        return <></>
      } else {
        return (
          <FormHelperText error>
            Ad Sizes/Fluid is required for Fluid Slot.
          </FormHelperText>
        )
      }
    } else {
      if (
        !(viewport.isSuppressed || viewport.isFluid) &&
        !!errors[viewport.siteViewportId]
      ) {
        return (
          <FormHelperText error>
            Ad Sizes are required for non-suppressed slots
          </FormHelperText>
        )
      } else {
        return <></>
      }
    }
  }

  const viewPortError = viewport => {
    if (newSlot.isFluid) {
      if (
        viewport.isFluid ||
        viewport.isSuppressed ||
        viewport.adSizes.filter(v => v.checked).length >= 1
      ) {
        return false
      } else {
        return true
      }
    } else {
      if (
        !(viewport.isSuppressed || viewport.isFluid) &&
        !!errors[viewport.siteViewportId]
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const handleSlotTypeChange = event => {
    setNewSlot({ ...newSlot, slotTypeId: event.target.value })
    if (event.target.value === '') {
      setValue('slotType', '', { shouldValidate: true })
      setValue('slotLocation', '', { shouldValidate: true })
    } else {
      slotTypeChange(event.target.value)
      setValue('slotType', event.target.value, { shouldValidate: true })
      setValue('slotLocation', '', { shouldValidate: true })
    }
  }

  const slotTypeChange = slotTypeId => {
    const slotType = slotTypes.find(slotType => slotType._id === slotTypeId)
    let validLocations = []
    if (slotType?.validLocationIds.length > 0) {
      validLocations = locations.filter(location =>
        slotType.validLocationIds.includes(location._id)
      )
    }
    const newSlotName = setSlotName(
      registry,
      newSlot,
      slotTypes,
      validLocations
    )
    setNewSlot({
      ...newSlot,
      name: newSlotName,
      slotTypeId
    })
    setSlotLocations(validLocations)
  }

  const handleLocationChange = event => {
    locationChange(event.target.value)
  }

  const locationChange = locationId => {
    const newSlotName = setSlotName(registry, newSlot, slotTypes, locations)
    setNewSlot({
      ...newSlot,
      name: newSlotName,
      locationId
    })
    setValue('slotLocation', locationId, { shouldValidate: true })
  }

  const handleAdSizesChange = event => {
    if (event.target.checked) {
      const selectedAdUnitSize = adUnitSizesUI.find(
        adUnitSize => adUnitSize.name === event.target.value
      )
      let _adUnitSizesUISelected = null
      const viewportMappings = viewports.map(viewport => {
        _adUnitSizesUISelected =
          viewport.adSizes && viewport.adSizes.length > 0
            ? viewport.adSizes.slice()
            : []
        const foundAdSizes = viewport.adSizes.find(
          adUnitSize => adUnitSize.name === event.target.value
        )
        if (!foundAdSizes) {
          _adUnitSizesUISelected.push(selectedAdUnitSize)
        }
        return {
          ...viewport,
          adSizes: _adUnitSizesUISelected,
          isAll: false
        }
      })
      setViewports(viewportMappings)
      setNewSlot({
        ...newSlot,
        adSizes: _adUnitSizesUISelected,
        viewportMappings
      })
    } else {
      if (viewports.length > 0) {
        const adUnitSize = viewports[0].adSizes.filter(
          adUnitSize => adUnitSize.name !== event.target.value
        )
        const viewportMappings = viewports.map(viewport => ({
          ...viewport,
          adSizes: adUnitSize
        }))
        setViewports(viewportMappings)
        setNewSlot({
          ...newSlot,
          adSizes: adUnitSize,
          viewportMappings
        })
      }
    }

    // find the index of object from array that you want to update
    const selectedAdSizeIndex = adUnitSizesUI.findIndex(
      adUnitSize => adUnitSize.name === event.target.value
    )

    // make new object of updated object.
    const updatedViewport = {
      ...adUnitSizesUI[selectedAdSizeIndex],
      checked: event.target.checked
    }

    // make final new array of objects by combining updated object.
    const updatedAdUnitSizesUI = [
      ...adUnitSizesUI.slice(0, selectedAdSizeIndex),
      updatedViewport,
      ...adUnitSizesUI.slice(selectedAdSizeIndex + 1)
    ]
    setAdUnitSizesUI(updatedAdUnitSizesUI)
    const adName = event.target.name.slice()
    setValue(
      adName,
      updatedAdUnitSizesUI.filter(adUnitSize => adUnitSize.checked),
      { shouldValidate: true }
    )
  }

  const handleViewPortAdSizeChange = (event, viewPort, adSize) => {
    // find the index of object from array that you want to update
    const selectedViewportIndex = viewports.findIndex(
      _viewport => _viewport.siteViewportId === viewPort.siteViewportId
    )

    // update viewports adSizes checked status
    const updatedAdSizes = viewports
      .find(_viewport => _viewport.siteViewportId === viewPort.siteViewportId)
      .adSizes.map(_adSize => ({
        ..._adSize,
        checked:
          _adSize.name === adSize.name ? event.target.checked : _adSize.checked
      }))

    // make counts of all adSizes and checked adSizes
    const checkboxCount = viewports[selectedViewportIndex].adSizes?.length
    const checkedAdSize = updatedAdSizes.filter(adSize => adSize.checked)

    // make new object of updated object.
    const updatedViewport = {
      ...viewports[selectedViewportIndex],
      adSizes: updatedAdSizes,
      isAll: checkboxCount === checkedAdSize?.length
    }

    // make final new array of objects by combining updated object.
    const updatedViewports = [
      ...viewports.slice(0, selectedViewportIndex),
      updatedViewport,
      ...viewports.slice(selectedViewportIndex + 1)
    ]
    setViewports(updatedViewports)
    setNewSlot({
      ...newSlot,
      viewportMappings: updatedViewports
    })
    setValue(
      viewPort.siteViewportId,
      updatedAdSizes.filter(adUnitSize => adUnitSize.checked),
      { shouldValidate: true }
    )
  }

  const getViewportMappings = viewports => {
    const viewportMappings = []
    viewports.forEach(viewport => {
      const _adSizes = viewport.adSizes.filter(
        adSize => adSize.checked === true
      )
      const selectedViewportIndex = viewports.findIndex(
        _viewport => _viewport.siteViewportId === viewport.siteViewportId
      )
      const updatedViewport = {
        ...viewports[selectedViewportIndex],
        adSizes: _adSizes
      }
      viewportMappings.push(updatedViewport)
    })
    return viewportMappings
  }

  const handleAllSizesCheck = (event, viewport) => {
    // find the index of object from array that you want to update
    const selectedViewportIndex = viewports.findIndex(
      _viewport => _viewport.siteViewportId === viewport.siteViewportId
    )

    // update viewports adSizes checked status
    const updatedAdSizes = viewports
      .find(_viewport => _viewport.siteViewportId === viewport.siteViewportId)
      .adSizes.map(adUnitSize => ({
        ...adUnitSize,
        checked: event.target.checked
      }))

    // make new object of updated object.
    const updatedViewport = {
      ...viewports[selectedViewportIndex],
      adSizes: updatedAdSizes,
      isAll: event.target.checked
    }

    // make final new array of objects by combining updated object.
    const updatedViewports = [
      ...viewports.slice(0, selectedViewportIndex),
      updatedViewport,
      ...viewports.slice(selectedViewportIndex + 1)
    ]
    setViewports(updatedViewports)
    setNewSlot({
      ...newSlot,
      viewportMappings: updatedViewports
    })
    setValue(
      viewport.siteViewportId,
      updatedAdSizes.filter(adUnitSize => adUnitSize.checked),
      { shouldValidate: true }
    )
  }

  const handleCustomTargetingForRegistrySave = bulkTargetings => {
    if (bulkTargetings) {
      const finalTargeting = []
      if (newSlot.targetings && newSlot.targetings.length > 0) {
        for (let y = 0; y < newSlot.targetings.length; y++) {
          const target = newSlot.targetings[y]
          if (
            Array.isArray(bulkTargetings[target.key]) &&
            bulkTargetings[target.key].length > 0
          ) {
            if (Array.isArray(target.value)) {
              for (let z = 0; z < target.value.length; z++) {
                bulkTargetings[target.key].push(target.value[z])
              }
            } else {
              bulkTargetings[target.key].push(target.value)
            }
          } else {
            if (Array.isArray(target.value)) {
              bulkTargetings[target.key] = target.value
            } else {
              bulkTargetings[target.key] = [target.value]
            }
          }
        }
      }
      bulkTargetings.forEach(bulkTarget => {
        if (bulkTarget.key !== undefined) {
          bulkTarget.values.forEach(value => {
            const targetingObject = {
              key: bulkTarget.key,
              value: value.name
            }
            finalTargeting.push(targetingObject)
          })
        }
      })
      setNewSlot({
        ...newSlot,
        targetings: finalTargeting
      })
    }
  }

  const toggleSlotDialogStatus = (status, isChanged) => {
    if (isChanged) {
      swal.fire({
        title: `${newSlot.name} has changes.`,
        text: 'Are you sure you want to close and lose your changes?',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(isclose => {
        if (isclose.isConfirmed) {
          dispatch(ToggleSlotDialogStatus(status))
        }
      })
    } else {
      dispatch(ToggleSlotDialogStatus(status))
    }
  }

  useEffect(() => {
    setCurrentRegistry(registry)
  }, [registry])

  useEffect(() => {
    if (site && slot) {
      const rootAdUnit = adUnitDictionary[site.rootAdUnitId]
      let adUnit = rootAdUnit
      const adUnitPathNames = slot.adUnitPath.split('/')
      // loop to find selected last Child in Ad Unit
      for (let index = 1; index < adUnitPathNames.length; index++) {
        if (!adUnit.hasChildren) {
          break
        }
        adUnit = adUnit.children.find(
          child => child.name === adUnitPathNames[index]
        )
        if (!adUnit) {
          break
        }
      }
      let adSizes = []
      if (slot.isFluid && slot.adSizes.length === 0) {
        adSizes = adUnit?.adUnitSizes?.slice().map(item => ({
          ...item,
          checked: false,
          name: item.width + 'x' + item.height
        }))
      } else {
        adSizes = adUnit?.adUnitSizes?.slice().map(item => ({
          ...item,
          checked: slot.adSizes.some(
            obj => obj.width + 'x' + obj.height === item.name
          ),
          name: item.width + 'x' + item.height
        }))
      }
      setAdUnitSizesUI(adSizes)

      let validLocations = []
      if (slot.viewportMappings.length > 0) {
        const viewportMappings = []
        slot.viewportMappings.forEach(item => {
          const viewportMapping = site.viewports.find(
            _viewport => _viewport.siteViewportId === item.siteViewportId
          )
          const newSlotAdSizes = slot.adSizes
            .slice()
            .map(item => ({ ...item, checked: false }))

          const copiedAdSizes = item.adSizes.map(item => ({
            checked: true,
            name: item.width + 'x' + item.height,
            ...item
          }))

          const resultAdSizes = newSlotAdSizes
            .filter(el => {
              return !copiedAdSizes.some(f => {
                return f.width === el.width && f.height === el.height
              })
            })
            .map(item => ({
              checked: false,
              name: item.width + 'x' + item.height,
              ...item
            }))
          const viewportAdSizes = [...copiedAdSizes, ...resultAdSizes]
          viewportMappings.push({
            name: viewportMapping.name,
            minHeight: viewportMapping.minHeight,
            minWidth: viewportMapping.minWidth,
            isFluid: item.isFluid,
            isSuppressed: item.isSuppressed,
            siteViewportId: item.siteViewportId,
            status: item.status,
            adSizes: viewportAdSizes,
            isAll: resultAdSizes.length === item.adSizes.length
          })
        })
        const _viewports = viewportMappings
          .slice()
          .sort((a, b) => b.minWidth - a.minWidth)
        setViewports(_viewports)

        if (registry.isSingleton) {
          const newSlotName = setSlotName(registry, slot, slotTypes, locations)
          let _customTargets = []
          if (slot.targetings && slot.targetings.length > 0) {
            _customTargets = slot.targetings.map(targeting => {
              if (targeting.key === 'pos') {
                return {
                  ...targeting,
                  value: newSlotName?.split(/_(.+)/)[1]
                }
              } else {
                return targeting
              }
            })
          } else {
            _customTargets.push({
              key: 'pos',
              value: newSlotName?.split(/_(.+)/)[1]
            })
          }
          setNewSlot({
            ...slot,
            name: newSlotName,
            targetings: _customTargets,
            viewportMappings: _viewports
          })
          setOriginalSlot({
            ...slot,
            name: newSlotName,
            viewportMappings: _viewports
          })
        } else {
          let _customTargets = []
          if (slot.targetings && slot.targetings.length > 0) {
            if (slot.targetings.some(targeting => targeting.key === 'pos')) {
              _customTargets = slot.targetings.map(targeting => {
                if (targeting.key === 'pos') {
                  return {
                    ...targeting,
                    value: slot.name?.split(/_(.+)/)[1]
                  }
                } else {
                  return targeting
                }
              })
            } else {
              _customTargets.push({
                key: 'pos',
                value: slot.name?.split(/_(.+)/)[1]
              })
            }
          } else {
            _customTargets.push({
              key: 'pos',
              value: slot.name?.split(/_(.+)/)[1]
            })
          }
          setNewSlot({
            ...slot,
            targetings: _customTargets,
            viewportMappings: _viewports
          })
          setOriginalSlot({
            ...slot,
            viewportMappings: _viewports
          })
        }

        // This sets slot type
        setValue('slotType', slot.slotTypeId, { shouldValidate: true })

        // This sets slot location provided slotTypeId
        if (slot.slotTypeId) {
          const slotType = slotTypes.find(
            slotType => slotType._id === slot.slotTypeId
          )
          // let validLocations = []
          if (slotType?.validLocationIds.length > 0) {
            validLocations = locations.filter(location =>
              slotType.validLocationIds.includes(location._id)
            )
          }
          setSlotLocations(validLocations)
        }
      } else {
        setNewSlot({ ...slot })
        setOriginalSlot({ ...slot })
        // This sets slot type
        setValue('slotType', slot.slotTypeId, { shouldValidate: true })

        // This sets slot location provided slotTypeId
        if (slot.slotTypeId) {
          const slotType = slotTypes.find(
            slotType => slotType._id === slot.slotTypeId
          )
          if (slotType?.validLocationIds.length > 0) {
            validLocations = locations.filter(location =>
              slotType.validLocationIds.includes(location._id)
            )
          }
          setSlotLocations(validLocations)
        }
      }
      setValue(
        'slotViewRefreshCount',
        parseInt(slot.inViewRefreshCount) === 0
          ? 5
          : parseInt(slot.inViewRefreshCount),
        {
          shouldValidate: true
        }
      )
      setValue(
        'slotViewRefreshInterval',
        parseInt(slot.inViewRefreshInterval) === 0
          ? 35
          : parseInt(slot.inViewRefreshInterval),
        {
          shouldValidate: true
        }
      )
    }
    setIsViewRefreshSlot(slot.hasInViewRefresh)
  }, [])

  useEffect(() => {
    if (slotTypes && slotTypes.length === 0) {
      dispatch(fetchSlotTypes())
    }
  }, [slotTypes])

  useEffect(() => {
    if (locations && locations.length === 0) {
      dispatch(fetchSlotLocations())
    }
  }, [locations])

  useEffect(() => {
    dispatch(
      ToggleIsChange(JSON.stringify(newSlot) !== JSON.stringify(originalSlot))
    )
  }, [newSlot])

  useEffect(() => {
    if (newSlot.name && (newSlot.slotTypeId || newSlot.locationId)) {
      const newSlotName = setSlotName(registry, newSlot, slotTypes, locations)
      if (newSlotName !== undefined) {
        setNewSlot({
          ...newSlot,
          name: newSlotName
        })
      }
    }
  }, [newSlot.slotTypeId, newSlot.locationId])

  useEffect(() => {
    if (newSlot.locationId && slotLocations) {
      locationChange(newSlot.locationId)
    }
  }, [slotLocations])

  useEffect(() => {
    const _viewports = viewports
      ?.slice()
      .sort((a, b) => b.minWidth - a.minWidth)
    if (newSlot.isFluid) {
      if (_viewports && _viewports.length > 0) {
        _viewports.forEach(viewport => {
          if (!viewport.isFluid) {
            setValue(
              'isFluid' + viewport.siteViewportId,
              viewport.adSizes.filter(adSize => adSize.checked),
              { shouldValidate: true }
            )
          }
        })
      }
    } else if (adUnitSizesUI && adUnitSizesUI.length > 0) {
      if (_viewports && _viewports.length > 0) {
        _viewports.forEach(viewport => {
          unregister('isFluid' + viewport.siteViewportId)
          if (!(viewport.isSuppressed || viewport.isFluid)) {
            setValue(
              viewport.siteViewportId,
              viewport.adSizes.filter(adSize => adSize.checked),
              { shouldValidate: true }
            )
          }
        })
      }
    }
    setValue(
      'adUnitSizes',
      adUnitSizesUI.filter(adUnitSize => adUnitSize.checked)
    )
  }, [adUnitSizesUI, viewports, newSlot])

  const sizeMapping = () => {
    if (viewports && viewports.length > 0) {
      return (
        <Grid container>
          {viewports.map((viewport, i) => (
            <Grid key={i} item xs={6}>
              <Box
                border={viewPortError(viewport) ? 1 : 0}
                borderColor={viewPortError(viewport) ? 'error.main' : ''}
              >
                <Paper
                  elevation={1}
                  className={[classes.paper, commonClasses.p_1].join(' ')}
                >
                  <Grid container>
                    <Grid item xs={6} className={commonClasses.pl_1}>
                      {viewport.name}
                    </Grid>
                    <Grid item xs={6} className={commonClasses.pr_1}>
                      <span className={classes.itemRight}>
                        [{viewport.minHeight}-{viewport.minWidth}]
                      </span>
                    </Grid>
                    <Grid item xs={12} className={classes.itemCenter}>
                      {viewport.adSizes.length > 0 && !viewport.isSuppressed ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                viewport.adSizes.filter(e => e.checked === true)
                                  .length === viewport.adSizes.length
                              }
                              value={viewport.isAll}
                              indeterminate={
                                viewport.adSizes.filter(e => e.checked === true)
                                  .length > 0 &&
                                viewport.adSizes.filter(e => e.checked === true)
                                  .length !== viewport.adSizes.length
                              }
                              onChange={e => {
                                handleAllSizesCheck(e, viewport)
                              }}
                              color='primary'
                            />
                          }
                          label='All Sizes'
                        />
                      ) : (
                        <></>
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={e => handleSuppressedChange(e, viewport)}
                            checked={viewport.isSuppressed}
                            value={viewport.isSuppressed}
                            color='primary'
                          />
                        }
                        label='Suppressed'
                      />
                      {newSlot.isFluid ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={viewport.isFluid}
                              {...register('Fluid' + viewport.siteViewportId, {
                                validate: validateFluid
                              })}
                              onChange={e => handleFluidChange(e, viewport)}
                              value={viewport.isFluid}
                              color='primary'
                            />
                          }
                          label='Fluid'
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                      <FormGroup row>
                        {viewportAdSizesMapping(viewport)}
                      </FormGroup>
                      {viewPortHelperText(viewport)}
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          ))}
        </Grid>
      )
    } else {
      return <></>
    }
  }

  const viewportAdSizesMapping = viewport => {
    if (viewport.adSizes.length > 0 && !viewport.isSuppressed) {
      return viewport.adSizes.map((adSize, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={adSize.checked}
              value={adSize.name}
              color='primary'
              {...register(viewport.siteViewportId, {
                validate: () => {
                  const values = getValues()
                  if (typeof values.adUnitSizes === 'object') {
                    if (typeof values[viewport.siteViewportId] === 'object') {
                      if (!viewport.isFluid) {
                        return (
                          values[viewport.siteViewportId].filter(v => Boolean(v))
                            .length >= 1 ||
                          'Ad Sizes are required for non-suppressed slots.'
                        )
                      } else {
                        return true
                      }
                    }
                  }
                }
              })}
              onChange={e => handleViewPortAdSizeChange(e, viewport, adSize)}
            />
          }
          label={adSize.name}
        />
      ))
    } else {
      return <></>
    }
  }

  const displayInViewRefreshOption = () => {
    return (
      currentRegistry.hasInViewRefresh
        ? (<></>)
        : (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item md={4} xs={4}>
                  {isViewRefresh ? (
                    <ToggleSwitch
                      label='InView Refresh'
                      name='slotInViewRefresh'
                      offLabel='Disabled'
                      onLabel='Enabled'
                      checked={!!newSlot.hasInViewRefresh}
                      onChange={e => {
                        toggleInViewRefresh(e)
                      }}
                      value={newSlot.hasInViewRefresh}
                      inLine
                      disabled
                    />
                  ) : (
                    <ToggleSwitch
                      label='InView Refresh'
                      name='slotInViewRefresh'
                      offLabel='Disabled'
                      onLabel='Enabled'
                      checked={!!newSlot.hasInViewRefresh}
                      onChange={e => {
                        toggleInViewRefresh(e)
                      }}
                      value={newSlot.hasInViewRefresh}
                      inLine
                    />
                  )}
                </Grid>
                <Grid item md={4} xs={4}>
                  {isViewRefreshSlot ? (
                    <>
                      <Paper className={classes.root}>
                        <TextField
                          {...register('slotViewRefreshCount')}
                          className={classes.input}
                          label='Refresh Count'
                          margin='dense'
                          id='slotViewRefreshCount'
                          variant='standard'
                          defaultValue={
                      newSlot.inViewRefreshCount === 0
                        ? 5
                        : newSlot.inViewRefreshCount
                    }
                          onChange={e => {
                            handleInViewRefreshChange(e)
                          }}
                          type='number'
                          InputProps={{
                            inputProps: { min: '5', step: '1' }
                          }}
                          error={!!errors.slotViewRefreshCount}
                          helperText={
                      errors.slotViewRefreshCount
                        ? errors.slotViewRefreshCount.message
                        : ''
                    }
                        />
                        <Divider
                          className={commonClasses.verticalDivider28}
                          orientation='vertical'
                        />
                        times
                      </Paper>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item md={4} xs={4}>
                  {isViewRefreshSlot ? (
                    <>
                      <Paper className={classes.root}>
                        <TextField
                          {...register('slotViewRefreshInterval')}
                          className={classes.input}
                          label='Refresh Interval'
                          margin='dense'
                          id='slotViewRefreshInterval'
                          variant='standard'
                          type='number'
                          InputProps={{
                            inputProps: { min: '35', step: '1' }
                          }}
                          defaultValue={
                      newSlot.inViewRefreshInterval === 0
                        ? 35
                        : newSlot.inViewRefreshInterval
                    }
                          onChange={e => {
                            handleInViewRefreshChange(e)
                          }}
                          error={!!errors.slotViewRefreshInterval}
                          helperText={
                      errors.slotViewRefreshInterval
                        ? errors.slotViewRefreshInterval.message
                        : ''
                    }
                        />
                        <Divider
                          className={commonClasses.verticalDivider28}
                          orientation='vertical'
                        />
                        seconds
                      </Paper>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </>)

    )
  }

  return (
    <Paper position='static'>
      <form autoComplete='off' noValidate>
        <CardHeader
          title={newSlot.name}
        // className={commonClasses.panelHeader}
        />
        <Divider />
        <CardContent className={classes.contant}>
          <Grid container className={commonClasses.m_1}>
            <Grid item xs={12} sm={12}>
              <Grid container className={commonClasses.m_1}>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    label='Ad Slot'
                    margin='dense'
                    id='slotName'
                    name='slotName'
                    variant='standard'
                    value={newSlot.name ? newSlot.name : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  {adUnitFilds(newSlot)}
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3}>
                      <FormControl
                        style={{ width: '70%' }}
                        className={classes.formControl}
                      >
                        <InputLabel id='select-slotTypes'>Slot Type</InputLabel>
                        <Select
                          {...register('slotType', {
                            validate: value => value !== ''
                          })}
                          labelId='select-slotTypes'
                          style={{ width: '99%', marginTop: '16px' }}
                          id='slotType'
                          value={newSlot.slotTypeId ? newSlot.slotTypeId : ''}
                          onChange={e => handleSlotTypeChange(e)}
                          error={!!errors.slotType}
                        >
                          <MenuItem key={-1} value='' />
                          {slotTypes.map(adUnit => (
                            <MenuItem key={adUnit._id} value={adUnit._id}>
                              {adUnit.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors.slotType && (
                          <FormHelperText error={!!errors.slotType}>
                            Required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl
                        style={{ width: '70%' }}
                        className={classes.formControl}
                      >
                        <InputLabel id='select-locations'>Location</InputLabel>
                        <Select
                          {...register('slotLocation', {
                            validate: value => value !== ''
                          })}
                          labelId='select-locations'
                          style={{ width: '99%', marginTop: '16px' }}
                          id='slotLocation'
                          value={newSlot.locationId ? newSlot.locationId : ''}
                          onChange={e => handleLocationChange(e)}
                          error={!!errors.slotLocation}
                        >
                          <MenuItem key={-1} value='' />
                          {slotLocations.map(location => (
                            <MenuItem key={location._id} value={location._id}>
                              {location.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors.slotLocation && (
                          <FormHelperText error={!!errors.slotLocation}>
                            Required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container className={commonClasses.m_1}>
                    <Grid item xs={1}>
                      <InputLabel
                        id='lbl-adUnitSizes'
                        className={commonClasses.mt_2}
                      >
                        Ad Sizes
                      </InputLabel>
                    </Grid>
                    <Grid item xs={10}>
                      <Box
                        border={errors.adUnitSizes ? 1 : 0}
                        borderColor={errors.adUnitSizes ? 'error.main' : ''}
                      >
                        <FormGroup aria-label='adUnitSizes' row>
                          {adUnitSizesUI.length > 0 ? (
                            adUnitSizesUI.map((adUnitSize, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    {...register('adUnitSizes', {
                                      validate: validateAdUnitSizes
                                    })}
                                    checked={adUnitSize.checked}
                                    value={
                                      adUnitSize.width + 'x' + adUnitSize.height
                                    }
                                    onChange={e => {
                                      handleAdSizesChange(e)
                                    }}
                                    color='primary'
                                  />
                                }
                                label={
                                  adUnitSize.width + 'x' + adUnitSize.height
                                }
                              />
                            ))
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        {!!errors.adUnitSizes && (
                          <FormHelperText error={!!errors.adUnitSizes}>
                            Ad Sizes are required.
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {currentRegistry.isSingleton ? (
                  <Grid item xs={12}>
                    <Grid container className={commonClasses.m_1}>
                      <Grid item xs={1}>
                        <InputLabel
                          id='lbl-Inheritance'
                          className={commonClasses.mt_2}
                        >
                          Inheritance
                        </InputLabel>
                      </Grid>
                      <Grid item xs={10}>
                        <RadioGroup
                          aria-label='Inheritance'
                          name='Inheritance'
                          value={
                            newSlot.isInheritAdUnitFromRegistry
                              ? newSlot.isInheritAdUnitFromRegistry
                                  ? 'true'
                                  : 'false'
                              : 'false'
                          }
                          onChange={handleInheritanceChange}
                        >
                          <FormControlLabel
                            value='true'
                            color='primary'
                            control={<Radio />}
                            label='Always use the selected Ad Unit'
                          />
                          <FormControlLabel
                            value='false'
                            color='primary'
                            control={<Radio />}
                            label='Inherit Ad Unit from first non-singleton Slot on page, if available'
                          />
                        </RadioGroup>
                        {newSlot.isInheritAdUnitFromRegistry ? (
                          <></>
                        ) : (
                          <Alert severity='warning'>
                            Inheritable Ad Units require AdFuel 0.9.1 or greater
                          </Alert>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
                {hasRole(['admin', 'inview-refresh-editor'], user)
                  ? displayInViewRefreshOption()
                  : null}
                {currentRegistry.safeFrameConfig
                  ? (<></>)
                  : (
                    <>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item md={4} xs={4}>
                            <ToggleSwitch
                              label='Set Safeframe Config'
                              name='slotSafeframeConfig'
                              offLabel='Disabled'
                              onLabel='Enabled'
                              checked={!!newSlot.safeFrameConfig}
                              onChange={e => handleSafeframeConfigChange(e)}
                              value={newSlot.safeFrameConfig}
                              inLine
                            />
                          </Grid>
                          <Grid item md={8} xs={8}>
                            {isSafeframeConfigEnable ? (
                              <>
                                <ToggleSwitch
                                  label='Sandbox'
                                  name='slotSandbox'
                                  offLabel='No'
                                  onLabel='Yes'
                                  checked={!!newSlot.safeFrameSandboxMode}
                                  onChange={e => handleSafeframeConfigChange(e)}
                                  value={newSlot.safeFrameSandboxMode}
                                  inLine
                                />
                                <ToggleSwitch
                                  label='Allow Overlay Expansion'
                                  name='slotAllowOverlayExpansion'
                                  offLabel='No'
                                  onLabel='Yes'
                                  checked={!!newSlot.safeFrameAllowOverlayExpansion}
                                  onChange={e => handleSafeframeConfigChange(e)}
                                  value={newSlot.safeFrameAllowOverlayExpansion}
                                  inLine
                                />
                                <ToggleSwitch
                                  label='Allow Push Expansion'
                                  name='slotAllowPushExpansion'
                                  offLabel='No'
                                  onLabel='Yes'
                                  checked={!!newSlot.safeFrameAllowPushExpansion}
                                  onChange={e => handleSafeframeConfigChange(e)}
                                  value={newSlot.safeFrameAllowPushExpansion}
                                  inLine
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                    )}

                <Grid item xs={12}>
                  <Grid container className={classes.ResponsiveContaner}>
                    <Grid item xs={2}>
                      <ToggleSwitch
                        label='Responsive'
                        name='responsive'
                        offLabel='No'
                        onLabel='Yes'
                        checked={!!newSlot.isResponsive}
                        onChange={e => toggleIsResponsive(e)}
                        value={newSlot.isResponsive}
                      />
                      <ToggleSwitch
                        label='Is Slot Fluid?'
                        name='isSlotFluid'
                        offLabel='No'
                        onLabel='Yes'
                        checked={!!newSlot.isFluid}
                        onChange={e => toggleIsFluid(e)}
                        value={newSlot.isFluid}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {newSlot.isResponsive ? (
                        <Card elevation={2} className={commonClasses.mb_2}>
                          <Typography className={commonClasses.m_1}>
                            Size Mappings
                          </Typography>
                          <Divider />
                          <CardContent>{sizeMapping(newSlot)}</CardContent>
                        </Card>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={classes.ResponsiveContaner}>
                    <Grid item xs={2} md={2} />
                    <Grid item xs={10} md={10}>
                      <Grid container>
                        <Grid item md={12} xs={12}>
                          <CustomTargetingAccordion
                            currentTargetings={newSlot?.targetings}
                            isSlotTargeting
                            slotName={newSlot?.name}
                            handleSave={handleCustomTargetingForRegistrySave}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.action}>
          <Box className={classes.box}>
            {isChanged ? (
              loading ? (
                <Button
                  disabled
                  variant='contained'
                  title='Saving...'
                  className={commonClasses.btnSuccess}
                  startIcon={<CircularProgress size={24} />}
                >
                  Saving
                </Button>
              ) : (
                <Button
                  className={[
                    commonClasses.btnSuccess,
                    commonClasses.mr_1
                  ].join(' ')}
                  title='Save'
                  variant='contained'
                  onClick={handleSubmit(UpdateSlot)}
                  startIcon={<CheckCircleIcon />}
                >
                  Save
                </Button>
              )
            ) : (
              <Button
                disabled
                className={commonClasses.mr_1}
                title='Save'
                variant='contained'
                startIcon={<CheckCircleIcon />}
              >
                Save
              </Button>
            )}
            <Button
              className={commonClasses.ml_1}
              title='Cancel'
              variant='contained'
              onClick={() => toggleSlotDialogStatus(false, isChanged)}
            >
              Cancel
            </Button>
          </Box>
        </CardActions>
      </form>
    </Paper>
  )
}