import React, { useState, useEffect } from 'react'
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Paper
} from '@material-ui/core'
import { Add, Edit, Clear, Check } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string as yupstring, object as yupobject } from 'yup'
import {
  updatePageTemplate,
  createPageTemplate,
  filterPageTemplates,
  fetchPageTemplates
} from '../../modules/pageTemplateSlice'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { commonStyle } from '../../styles'
import { LoadingProgress } from '../ui'
import { fetchRocketeerSites } from '../../modules/sitesSlice'

const swal = withReactContent(Swal)

const PageTemplates = props => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { pageTemplates } = useSelector(state => state.pageTemplateSlice)
  const { sites } = useSelector(state => state.sitesSlice)
  const [searchTerm, setSearchTerm] = useState('')
  const [pageTemplatesData, setPageTemplatesData] = useState([])
  const [selectedPageTemplate, setSelectedPageTemplate] = useState({})
  const [addingNewPageTemplate, setAddingNewPageTemplate] = useState(false)
  const [isEditingPageTemplate, setIsEditingPageTemplate] = useState(false)

  const validationSchema = yupobject().shape({
    name: yupstring().required('Required'),
    siteId: yupstring().required('Required')
  })

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const onSearchChange = e => {
    setSearchTerm(e.target.value)
    dispatch(filterPageTemplates(e.target.value))
  }

  const onEditPageTemplates = pageTemplate => {
    setSelectedPageTemplate(pageTemplate)
    setIsEditingPageTemplate(true)
    setAddingNewPageTemplate(false)
    setValue('siteId', pageTemplate.siteId, { shouldValidate: true })
    setValue('name', pageTemplate.name, { shouldValidate: true })
  }

  const onAddPageTemplate = () => {
    setSelectedPageTemplate({ name: '', siteId: '' })
    setAddingNewPageTemplate(true)
    setIsEditingPageTemplate(false)
    setValue('siteId', '')
    setValue('name', '')
  }

  const handlePageTemplateChange = e => {
    if (e.target.name === 'name') {
      setSelectedPageTemplate({
        ...selectedPageTemplate,
        name: e.target.value
      })
      setValue('name', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'siteId') {
      setSelectedPageTemplate({
        ...selectedPageTemplate,
        siteId: e.target.value
      })
      setValue('siteId', e.target.value, { shouldValidate: true })
    }
  }

  const UpdatePageTemplate = () => {
    console.log('Updating Page Template...', { selectedPageTemplate })
    dispatch(updatePageTemplate(selectedPageTemplate))
    setIsEditingPageTemplate(false)
  }

  const CreatePageTemplate = () => {
    console.log("Creating Page Template!", { selectedPageTemplate })
    const itemIndex = pageTemplatesData.findIndex(
      pageTemplate => pageTemplate.name === selectedPageTemplate.name
    )
    if (itemIndex === -1) {
      dispatch(createPageTemplate(selectedPageTemplate))
      setAddingNewPageTemplate(false)
    } else {
      swal.fire({
        title: 'Duplicate record!',
        text: 'Duplicate record already exists.  Please modify it and resave.',
        icon: 'error'
      })
    }
  }

  useEffect(() => {
    if (sites && sites.length === 0) {
      dispatch(fetchRocketeerSites())
    }
  }, [sites, dispatch])

  useEffect(() => {
    if (pageTemplates && pageTemplates.length > 0) {
      setPageTemplatesData(
        pageTemplates.map(pTemplate => ({ ...pTemplate }))
      )
    } else {
      dispatch(fetchPageTemplates())
    }
  }, [pageTemplates, dispatch])

  const textField = (
    isEditingPageTemplate,
    selectedPageTemplate,
    pageTemplate,
    pageTemplateFor,
    pageTemplateDefaultValue,
    handlePageTemplateChange,
    error,
    errorMessage
  ) => {
    if (
      isEditingPageTemplate &&
      selectedPageTemplate._id === pageTemplate._id
    ) {
      return (
        <TextField
          style={{ width: '100%' }}
          margin='dense'
          {...register(pageTemplateFor)}
          defaultValue={pageTemplateDefaultValue}
          variant='outlined'
          onChange={e => handlePageTemplateChange(e)}
          error={!!error}
          helperText={error ? errorMessage : ''}
        />
      )
    } else {
      return (
        <div className={commonClasses.plainText}>{pageTemplateDefaultValue}</div>
      )
    }
  }

  const selectField = (
    isEditingPageTemplate,
    selectedPageTemplate,
    pageTemplate,
    siteIdFor,
    siteIdDefaultValue,
    siteIdDisplayValue,
    handlePageTemplateChange,
    error,
    errorMessage
  ) => {
    if (
      isEditingPageTemplate &&
      selectedPageTemplate._id === pageTemplate._id
    ) {
      return (
        <FormControl
          style={{ width: '70%' }}
          className={commonClasses.formControl}
        >
          <Select
            {...register(siteIdFor, {
              validate: value => value !== ''
            })}
            labelId='select-siteId'
            style={{ width: '99%', marginTop: '8px' }}
            id='siteId'
            defaultValue={siteIdDefaultValue}
            onChange={e => handlePageTemplateChange(e)}
            error={!!error}
            helperText={error ? errorMessage : ''}
            // disabled={true}
          >
            <MenuItem key={-1} value='' />
            {sites.map(site => (
              <MenuItem key={site._id} value={site._id}>
                {site.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    } else {
      return (
        <div className={commonClasses.plainText}>{siteIdDisplayValue}</div>
      )      
    }
  }

  console.log('Sites: ', sites)
  return (
    <Container maxWidth={'lg'}>
      <Paper position='static'>
        <Card>
          <form autoComplete='off' id='PageTemplate' noValidate>
          <CardHeader title='Page Templates' />          
          <Divider />
          <CardContent className={commonClasses.cardBkClr}>
            <Card><CardContent>
            <Grid container style={{ marginBottom: '10pt' }}>
              <Grid item md={4} xs={12}>
                <TextField
                  style={{ width: '100%' }}
                  label='Find'
                  margin='dense'
                  name='itemFilter'
                  defaultValue={searchTerm}
                  variant='outlined'
                  onChange={onSearchChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant='contained'
                  color='primary'
                  className={commonClasses.m_1}
                  startIcon={<Add />}
                  onClick={() => {
                    onAddPageTemplate()
                  }}
                >
                  Add New Page Template
                </Button>
              </Grid>
              <Grid item md={2} />
            </Grid>
            <Divider />
            <Grid>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={commonClasses.fontWeightBold}
                      style={{ width: '55%' }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      className={commonClasses.fontWeightBold}
                      style={{ width: '30%' }}
                    >
                      Site
                    </TableCell>
                    <TableCell
                      className={commonClasses.fontWeightBold}
                      style={{ width: '15%' }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    className={!addingNewPageTemplate ? commonClasses.hidden : ''}
                  >
                    <TableCell>
                      <TextField
                        {...register('name')}
                        margin='dense'
                        defaultValue={selectedPageTemplate.name}
                        style={{ width: '100%' }}
                        variant='outlined'
                        onChange={e => handlePageTemplateChange(e)}
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl
                        style={{ width: '70%' }}
                        className={commonClasses.formControl}
                      >
                        <InputLabel id='select-siteId'>Site</InputLabel>
                        <Select
                          {...register('siteId', {
                            validate: value => value !== ''
                          })}
                          style={{ width: '99%', marginTop: '16px' }}
                          id='siteId'
                          defaultValue={selectedPageTemplate.siteId}
                          onChange={e => handlePageTemplateChange(e)}
                          error={!!errors.siteId}
                          // disabled={true}
                        >
                          <MenuItem key={-1} value='' />
                          {sites.map(site => (
                            <MenuItem key={site._id} value={site._id}>
                              {site.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Grid container>
                        <Grid className={commonClasses.displayFlex} item>
                          <IconButton
                            aria-label='Save'
                            variant='contained'
                            name='Save'
                            title='Save'
                            onClick={handleSubmit(CreatePageTemplate)}
                          >
                            <Check />
                          </IconButton>
                          <IconButton
                            aria-label='Cancel'
                            variant='contained'
                            title='Cancel'
                            name='cancel'
                            onClick={e => {
                              setAddingNewPageTemplate(false)
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  {pageTemplatesData.length > 0
                    ? (
                        pageTemplatesData.map(pageTemplate => {
                          const siteName = sites.filter(site => site._id === pageTemplate.siteId)[0]?.name || ''
                          console.log('siteName: ', siteName)
                          return (
                            <TableRow
                              hover
                              key={pageTemplate._id}
                              style={{
                                verticalAlign: 'top'
                              }}
                            >
                              <TableCell>
                                {textField(
                                  isEditingPageTemplate,
                                  selectedPageTemplate,
                                  pageTemplate,
                                  'name',
                                  pageTemplate.name,
                                  handlePageTemplateChange,
                                  errors.name,
                                  errors.name ? errors.name.message : ''
                                )}
                              </TableCell>
                              <TableCell>
                                {
                                  selectField(
                                    isEditingPageTemplate,
                                    selectedPageTemplate,
                                    pageTemplate,
                                    'siteId',
                                    pageTemplate.siteId,
                                    siteName,
                                    handlePageTemplateChange,
                                    errors.siteId,
                                    errors.siteId ? errors.siteId.message : ''
                                  )
                                }
                              </TableCell>
                              <TableCell>
                                <Grid container>
                                  <Grid className={commonClasses.displayFlex} item>
                                    <IconButton
                                      aria-label='Save'
                                      variant='contained'
                                      className={
                                  isEditingPageTemplate &&
                                  selectedPageTemplate._id === pageTemplate._id
                                    ? ''
                                    : commonClasses.hidden
                                }
                                      name='Save'
                                      title='Save'
                                      onClick={handleSubmit(UpdatePageTemplate)}
                                    >
                                      <Check />
                                    </IconButton>
                                    <IconButton
                                      aria-label='Cancel'
                                      variant='contained'
                                      className={
                                  isEditingPageTemplate &&
                                  selectedPageTemplate._id === pageTemplate._id
                                    ? ''
                                    : commonClasses.hidden
                                }
                                      title='Cancel'
                                      onClick={e => {
                                        e.stopPropagation()
                                        setIsEditingPageTemplate(false)
                                      }}
                                    >
                                      <Clear />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid className={commonClasses.displayFlex} item>

                                    <IconButton
                                      aria-label='Edit'
                                      variant='contained'
                                      className={
                                  isEditingPageTemplate &&
                                  selectedPageTemplate._id === pageTemplate._id
                                    ? commonClasses.hidden
                                    : commonClasses.editIconColor
                                }
                                      name='Edit'
                                      title='Edit'
                                      onClick={e => {
                                        onEditPageTemplates(pageTemplate)
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      )
                    : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <LoadingProgress />
                        </TableCell>
                      </TableRow>
                      )}
                </TableBody>
              </Table>
            </Grid>
            </CardContent></Card>
          </CardContent>
        </form>
        </Card>
      </Paper>
    </Container>
  )
}

export default PageTemplates
