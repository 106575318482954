import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  sitePageTemplates: [],
  orgSitePageTemplates: [],
  pageTemplates: [],
  orgPageTemplates: [],
  loading: false
}

const pageTemplateSlice = createSlice({
  name: 'pageTemplate',
  initialState,
  reducers: {
    FetchPageTemplates: (state, action) => {
      state.loading = false
      state.pageTemplates = action.payload
      state.orgPageTemplates = action.payload
    },
    FetchSitePageTemplates: (state, action) => {
      console.log('Site Page Templates:', { state, action })
      state.loading = false
      state.sitePageTemplates = action.payload
      state.orgSitePageTemplates = action.payload
    },
    UpdatePageTemplates: (state, action) => {
      const pageTemplates = state.pageTemplates.slice()
      const updatedItemIndex = pageTemplates.findIndex(
        pageTemplate => pageTemplate._id === action.payload._id
      )
      pageTemplates[updatedItemIndex] = action.payload

      state.loading = false
      state.pageTemplates = pageTemplates
      state.orgPageTemplates = pageTemplates
      swal.fire({
        title: 'Page Templates Updated!',
        text: 'Page Templates Updated.',
        icon: 'success'
      })
    },
    CreatePageTemplates: (state, action) => {
      const pageTemplates = state.pageTemplates.slice()
      pageTemplates.unshift(action.payload)
      state.loading = false
      state.pageTemplates = pageTemplates
      state.orgPageTemplates = pageTemplates
      swal.fire({
        title: 'Page Templates Saved!',
        text: 'Page Templates saved successfully.',
        icon: 'success'
      })
    },
    FilterPageTemplates: (state, action) => {
      const results = filterByValue(state.orgPageTemplates, action.payload)
      state.loading = false
      state.pageTemplates = results
    },
    PageTemplatesLoadingStatus: state => {
      state.loading = true
    },
    PageTemplatesError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    }
  }
})

export default pageTemplateSlice.reducer
export const {
  FetchPageTemplates,
  FetchSitePageTemplates,
  UpdatePageTemplates,
  CreatePageTemplates,
  FilterPageTemplates,
  PageTemplatesLoadingStatus,
  PageTemplatesError
} = pageTemplateSlice.actions

const filterByValue = (array, string) => {
  return array.filter(arrObject =>
    Object.keys(arrObject).some(element =>
      // Some() allows you to test if an array has at least one element that meets a condition.
      arrObject[element]
        .toString()
        .toLowerCase()
        .includes(string.toLowerCase())
    )
  )
}

export const fetchPageTemplates = () => {
  return async dispatch => {
    dispatch(PageTemplatesLoadingStatus())
    return request('/lookups/templates')
      .then(res => {
        dispatch(FetchPageTemplates(res.data))
      })
      .catch(err => dispatch(PageTemplatesError(err)))
  }
}

export const fetchPageTemplatesForSite = (siteId) => {
  return async dispatch => {
    dispatch(PageTemplatesLoadingStatus())
    return request('/lookups/templates/'+siteId)
      .then(res => {
        dispatch(FetchSitePageTemplates(res.data))
      })
      .catch(err => dispatch(PageTemplatesError(err)))
  }
}

export const updatePageTemplate = pageTemplate => {
  return async dispatch => {
    dispatch(PageTemplatesLoadingStatus())
    return request(
      `/lookups/template/${pageTemplate._id}`,
      'POST',
      pageTemplate
    )
      .then(res => {
        dispatch(UpdatePageTemplates(res.data))
      })
      .catch(err => dispatch(PageTemplatesError(err)))
  }
}

export const createPageTemplate = pageTemplate => {
  return async dispatch => {
    dispatch(PageTemplatesLoadingStatus())
    return request('/lookups/templates', 'POST', pageTemplate)
      .then(res => dispatch(CreatePageTemplates(res.data)))
      .catch(err => dispatch(PageTemplatesError(err)))
  }
}

export const filterPageTemplates = searchTerm => {
  return async dispatch => {
    dispatch(PageTemplatesLoadingStatus())
    dispatch(FilterPageTemplates(searchTerm))
  }
}
