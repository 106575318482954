import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { addClasses, commonStyle } from './../../styles'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import {
  LinearProgress,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  InputLabel,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  TextField,
  Button,
  DialogActions,
  CircularProgress,
  useMediaQuery,
  IconButton
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Add from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { CustomDialog, Toast } from '../ui'
import {
  filterRegistrySlots,
  ToggleIsNewStatus,
  NewSlotResource,
  cloneSlotResource,
  addRegistrySlot,
  addSlotToNewRegistry,
  deleteRegistrySlot,
  deleteSlotFromNewRegistry,
  OpenToast,
  CloseToast,
  setSlotName,
  ToggleSlotDialogStatus
} from '../../modules/slotSlice'
import { CustomTargetingAccordion } from '../sites/CustomTargetingAccordion'
import { ToggleSwitch } from './../ui/ToggleSwitch'
import { Slot } from './index.js'
import { ToggleCopyRegistryStatus } from './../../modules/registrySlice'

const swal = withReactContent(Swal)

const useStyle = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 170
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  itemRight: { textAlign: 'right', float: 'right' },
  itemCenter: { textAlign: 'Center' },
  divider: {
    height: 28,
    margin: 4
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  ConfigurationItemContainer: {
    paddingTop: '4px'
  },
  ConfigurationItems: {
    margin: '2px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '95%',
    border: 'none',
    boxShadow: 'none'
  },  
}))

const Slots = ({ isSingleton = true, isRegistryChanged = false }) => {
  const theme = useTheme()
  const classes = useStyle()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { site } = useSelector(state => state.sitesSlice)
  const {
    isNew,
    slots,
    registry,
    adUnitDictionary,
    loading,
    isDeleting,
    selectedItemId,
    newSlotResource,
    toast,
    openSlotDialog,
    isChanged
  } = useSelector(state => state.slotSlice)
  const { slotTypes } = useSelector(state => state.slotTypeSlice)
  const { locations } = useSelector(state => state.slotLocationSlice)
  const { isCopyRegistry, isViewRefresh, isSafeFrameConfig } = useSelector(
    state => state.registrySlice
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [currentRegistry, setCurrentRegistry] = useState([])
  const [registrySlots, setRegistrySlots] = useState([])
  const [newSlot, setNewSlot] = useState([])
  const [adUnitSizesUI, setAdUnitSizesUI] = useState([])
  const [isSafeframeConfigEnable, setIsSafeframeConfigEnable] = useState(false)
  const [isViewRefreshSlot, setIsViewRefreshSlot] = useState(false)
  const [slotLocations, setSlotLocations] = useState([])
  const [viewports, setViewports] = useState([])
  const [isCopy, setIsCopy] = useState(false)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [dialog, setDialog] = useState({ content: '' })
  const [betaTest] = useState(
    window.localStorage.getItem('beta') === 'true'
  )
  
  const validateAdUnitSizes = () => {
    const values = getValues()
    const msg = 'Ad Sizes are required.'
    if (!newSlot.isFluid) {
      if (
        typeof values.adUnitSizes === 'undefined' ||
        values.adUnitSizes === undefined
      ) {
        return msg
      } else if (typeof values.adUnitSizes === 'object') {
        return values.adUnitSizes.filter(v => Boolean(v)).length >= 1 || msg
      }
    } else {
      return true
    }
  }

  const validateFluid = () => {
    const msg = 'Fluid is required for Fluid Slot.'
    if (newSlot.isFluid) {
      const values = getValues()
      return (
        viewports.filter(viewport => Boolean(viewport.isFluid))?.length ===
          viewports.length ||
        values.adUnitSizes.filter(v => Boolean(v)).length >= 1 ||
        msg
      )
    } else {
      return true
    }
  }

  const validateInViewRefreshCount = () => {
    const values = getValues()
    if (isNaN(values.slotViewRefreshCount)) {
      return 'Count must be a number'
    } else if (values.slotViewRefreshCount < 5) {
      return 'Min value 5'
    } else {
      return true
    }
  }

  const validateInViewRefreshInterval = () => {
    const values = getValues()
    if (isNaN(values.slotViewRefreshInterval)) {
      return 'Count must be a number'
    } else if (values.slotViewRefreshInterval < 35) {
      return 'Min value 35'
    } else {
      return true
    }
  }

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    unregister,
    handleSubmit
  } = useForm({
    submitFocusError: false
  })

  // Filter
  const onSearchChange = e => {
    setSearchTerm(e.target.value)
    dispatch(filterRegistrySlots(e.target.value))
  }

  const onAddSlot = () => {
    dispatch(
      NewSlotResource({
        site,
        isResponsive: site.isResponsive,
        hasInViewRefresh: currentRegistry.hasInViewRefresh,
        inViewRefreshCount: registry.inViewRefreshCount,
        inViewRefreshInterval: registry.inViewRefreshInterval,
        safeFrameConfig: isSafeFrameConfig,
        safeFrameAllowOverlayExpansion: registry.safeFrameAllowOverlayExpansion,
        safeFrameAllowPushExpansion: registry.safeFrameAllowPushExpansion,
        safeFrameSandboxMode: registry.safeFrameSandboxMode
      })
    )
    setIsSafeframeConfigEnable(isSafeFrameConfig)
    dispatch(ToggleIsNewStatus(true))
  }

  const onCopySlot = slot => {
    dispatch(cloneSlotResource({ slot, site }))
    dispatch(ToggleIsNewStatus(true))
    setIsCopy(true)
  }

  const onEditSlot = slot => {
    const dialog = {
      // content: <OGSlot data={slot} isSingleton={isSingleton} />,
      content: <Slot data={slot} isSingleton={isSingleton} />,
      displayDialogAction: false
    }
    setDialog(dialog)
    dispatch(ToggleSlotDialogStatus(true))
  }

  const toggleSlotDialogStatus = status => {
    if (isChanged) {
      swal.fire({
        title: `${newSlot.name} has changes.`,
        text: 'Are you sure you want to close and lose your changes?',
        icon: 'warning',
        showCancelButton: true
      }).then(isclose => {
        if (isclose.isConfirmed) {
          dispatch(ToggleSlotDialogStatus(status))
        }
      })
    } else {
      dispatch(ToggleSlotDialogStatus(status))
    }
  }

  const handleSlotLeafAdUnitChange = (event, index) => {
    // #region  set the slot's leaf adUnit when the UI changes
    const rootAdUnit = adUnitDictionary[site.rootAdUnitId]
    let adUnitIds = []
    if (newSlot.adUnitPath) {
      let adUnit = rootAdUnit
      const adUnitPathNames = newSlot.adUnitPath.split('/')
      let i = 1
      while (i < adUnitPathNames.length) {
        if (!adUnit.hasChildren) {
          break
        }
        adUnit = adUnit.children.find(child => child.name === adUnitPathNames[i])
        if (!adUnit) {
          break
        }
        adUnitIds.push(adUnit._id)
        i++
      }
      if (adUnitIds.length !== adUnitPathNames.length - 1) {
        // this full adUnitPath could not be found because some part of it is inactive, so notify user
        dispatch(
          OpenToast({
            message: `${newSlot.name} is using the following Ad Unit, which is inactive.  ${newSlot.adUnitPath}`,
            variant: 'error'
          })
        )
      }

      const RsltAdUnitIds =
        adUnitIds.length > 0 ? adUnitIds.splice(0, index) : adUnitIds
      adUnitIds = RsltAdUnitIds
      if (event.target.value !== -1) {
        adUnitIds.push(event.target.value)
      }
    }
    let newAdUnitId = rootAdUnit._id
    let adUnitPath = rootAdUnit.name
    let adUnitPathName = rootAdUnit.name
    adUnitIds.forEach(adUnitId => {
      const adUnit = adUnitDictionary[adUnitId]
      if (adUnit) {
        // ignore trailing nulls
        adUnitPath += '/' + adUnit.name
        adUnitPathName += '_' + adUnit.name?.toUpperCase()
        newAdUnitId = adUnit._id
      }
    })

    // set adsizes based on chosen adUnit
    const newAdUnit = adUnitDictionary[newAdUnitId]
    const adUnitSizes = newAdUnit.adUnitSizes
      .slice()
      .map(item => ({
        ...item,
        checked: false,
        name: item.width + 'x' + item.height
      }))
      .map(obj => adUnitSizesUI.find(o => o.name === obj.name) || obj)
    const adSizes = []
    const invalidAdSizes = []
    newSlot.adSizes.forEach(adSize => {
      let adSizeFound = false
      // If size is fluid (0x0), allow it through.
      for (let i = 0, len = adUnitSizes.length; i < len; i++) {
        // look for first matching adUnitSize
        const adUnitSize = adUnitSizes[i]
        if (
          adUnitSize.width === adSize.width &&
          adUnitSize.height === adSize.height
        ) {
          adSizes.push(adSize)
          adSizeFound = true
          break
        }
      }
      if (!adSizeFound && adSize.width !== 0) {
        invalidAdSizes.push(adSize)
      }
    })

    setAdUnitSizesUI(adUnitSizes)

    const checkedAdUnitSizes = adUnitSizes
      .filter(item => item.checked === true)
      .map(item => ({
        ...item,
        checked: false
      }))
    const viewportMappings = viewports.map(viewport => ({
      ...viewport,
      adSizes: checkedAdUnitSizes
    }))
    setViewports(viewportMappings)

    setNewSlot({
      ...newSlot,
      adUnitPath,
      adUnitPathName,
      adSizes,
      viewportMappings: getViewportMappings(viewportMappings)
    })

    if (invalidAdSizes.length > 0) {
      const invalidAdSizesDisplay = invalidAdSizes
        .map(function (invalidAdSize) {
          return invalidAdSize.width + 'x' + invalidAdSize.height
        })
        .join(', ')
      dispatch(
        OpenToast({
          message: `invalid AdSize ${
            invalidAdSizes.length > 1 ? 's' : ''
          } for ${newSlot.name}: ${invalidAdSizesDisplay} - ${
            invalidAdSizes.length > 1 ? 'they have' : 'it has'
          }  been removed.`,
          variant: 'error'
        })
      )
    }
  }

  const AddNewSlot = () => {
    const currentSlots = currentRegistry.slots ? currentRegistry.slots?.slice() : []
    currentSlots.push(newSlot)
    let updatedRegistry = null
    if (site.useWOPR) {
      updatedRegistry = {
        ...currentRegistry,
        name: currentSlots.length === 1 ? currentSlots[0].adUnitPathName : currentSlots.length > 1 ? currentSlots[0].adUnitPathName : '',
        isSingleton,
        slots: currentSlots
      }
    } else {
      updatedRegistry = {
        ...currentRegistry,
        isSingleton,
        slots: currentSlots
      }
    }
    if (currentRegistry._id === undefined) {
      // In case of new Registry only
      dispatch(addSlotToNewRegistry(updatedRegistry))
    } else {
      dispatch(addRegistrySlot(updatedRegistry))
    }
    unregisterValidation()
  }

  const unregisterValidation = () => {
    unregister('adUnitSizes')
    unregister('slotLocation')
    unregister('slotType')
    viewports.forEach(element => {
      unregister(element.name)
    })
  }

  const adUnitFields = () => {
    const rootAdUnit = adUnitDictionary[site.rootAdUnitId]
    const adUnitIds = []
    if (newSlot.adUnitPath) {
      let adUnit = rootAdUnit
      const adUnitPathNames = newSlot.adUnitPath.split('/')
      let i = 1
      while (i < adUnitPathNames.length) {
        if (!adUnit.hasChildren) {
          break
        }
        adUnit = adUnit.children.find(x => x.name === adUnitPathNames[i])
        if (!adUnit) {
          break
        }
        adUnitIds.push(adUnit._id)
        i++
      }
      return (
        <Grid container row='true' className={commonClasses.m_1}>
          <Grid item xs={2} sm={2}>
            <TextField
              style={{ width: '85%' }}
              disabled
              label='Ad Unit'
              name='adUnit'
              margin='dense'
              id={currentRegistry.name}
              value={newSlot.adUnitPath.split('/')[0]}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds.length > 0 &&
            adUnitDictionary[site.rootAdUnitId].children
              ? (<span> / </span>)
              : (<span className={commonClasses.pr_1} />)}
            <Select
              style={{ width: '85%', marginTop: '16px' }}
              id='adUnitId-0'
              value={adUnitIds.length > 0 && adUnitIds[0] ? adUnitIds[0] : -1}
              name='adUnitId-0'
              onChange={e => handleSlotLeafAdUnitChange(e, 0)}
            >
              <MenuItem key={-1} value={-1} />
              {adUnitDictionary[site.rootAdUnitId].children?.map(adUnit => (
                <MenuItem key={adUnit.name} value={adUnit._id}>
                  {adUnit.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds[1] ? <span> / </span> : <span />}
            {adUnitIds[0] && adUnitDictionary[adUnitIds[0]].children
              ? (
                <Select
                  style={{ width: '85%', marginTop: '16px' }}
                  id='adUnitId-1'
                  value={adUnitIds[1] ? adUnitIds[1] : -1}
                  name='adUnitId-1'
                  onChange={e => handleSlotLeafAdUnitChange(e, 1)}
                >
                  <MenuItem key={-1} value={-1} />
                  {adUnitDictionary[adUnitIds[0]].children.map(adUnit => (
                    <MenuItem key={adUnit.name} value={adUnit._id}>
                      {adUnit.name}
                    </MenuItem>
                  ))}
                </Select>
                )
              : (
                <span />
                )}
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds[2] ? <span> / </span> : <span />}
            {adUnitIds[1] && adUnitDictionary[adUnitIds[1]].children
              ? (
                <Select
                  style={{ width: '85%', marginTop: '16px' }}
                  id='adUnitId-2'
                  value={adUnitIds[2] ? adUnitIds[2] : -1}
                  name='adUnitId-2'
                  onChange={e => handleSlotLeafAdUnitChange(e, 2)}
                >
                  <MenuItem key={-1} value={-1} />
                  {adUnitDictionary[adUnitIds[1]].children.map(adUnit => (
                    <MenuItem key={adUnit.name} value={adUnit._id}>
                      {adUnit.name}
                    </MenuItem>
                  ))}
                </Select>
                )
              : (
                <span />
                )}
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds[3] ? <span> / </span> : <span />}
            {adUnitIds[2] && adUnitDictionary[adUnitIds[2]].children
              ? (
                <Select
                  style={{ width: '85%', marginTop: '16px' }}
                  id='adUnitId-2'
                  value={adUnitIds[3] ? adUnitIds[3] : -1}
                  name='adUnitId-2'
                  onChange={e => handleSlotLeafAdUnitChange(e, 3)}
                >
                  <MenuItem key={-1} value={-1} />
                  {adUnitDictionary[adUnitIds[2]].children.map(adUnit => (
                    <MenuItem key={adUnit.name} value={adUnit._id}>
                      {adUnit.name}
                    </MenuItem>
                  ))}
                </Select>
                )
              : (
                <span />
                )}
          </Grid>
          <Grid item xs={2} sm={2}>
            {adUnitIds[4]
              ? (
                <span> / </span>
                )
              : (
                <span className={commonClasses.ml_2} />
                )}
            {adUnitIds[3] && adUnitDictionary[adUnitIds[3]].children
              ? (
                <Select
                  style={{ width: '85%', marginTop: '16px' }}
                  id='adUnitId-2'
                  value={adUnitIds[4] ? adUnitIds[4] : -1}
                  name='adUnitId-2'
                  onChange={e => handleSlotLeafAdUnitChange(e, 4)}
                >
                  <MenuItem key={-1} value={-1} />
                  {adUnitDictionary[adUnitIds[3]].children.map(adUnit => (
                    <MenuItem key={adUnit.name} value={adUnit._id}>
                      {adUnit.name}
                    </MenuItem>
                  ))}
                </Select>
                )
              : (
                <span />
                )}
          </Grid>
        </Grid>
      )
    }
  }

  const onDeleteSlot = slot => {
    swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, will not be able to recover this record!',
      icon: 'warning',
      showCancelButton: true
      // buttons: true,
      // dangerMode: true
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        if (currentRegistry._id === undefined) {
          // In case of new Rsegistry only
          const filteredSlot = currentRegistry.slots.filter(
            _slot => _slot.name !== slot.name
          )
          const updatedRegistry = { ...currentRegistry, slots: filteredSlot }
          dispatch(deleteSlotFromNewRegistry(updatedRegistry))
        } else {
          const filteredSlot = currentRegistry.slots.filter(
            _slot => _slot._id !== slot._id
          )
          const updatedRegistry = { ...currentRegistry, slots: filteredSlot }
          dispatch(deleteRegistrySlot(updatedRegistry))
        }
      }
    })
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(CloseToast())
  }

  const toggleInViewRefresh = event => {
    setNewSlot({
      ...newSlot,
      hasInViewRefresh: event.target.checked
    })
    setIsViewRefreshSlot(event.target.checked)
  }

  const handleInViewRefreshChange = e => {
    switch (e.target.name) {
      case 'slotViewRefreshCount':
        setNewSlot({
          ...newSlot,
          inViewRefreshCount: e.target.value
        })
        setValue('slotViewRefreshCount', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      case 'slotViewRefreshInterval':
        setNewSlot({
          ...newSlot,
          inViewRefreshInterval: e.target.value
        })
        setValue('slotViewRefreshInterval', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      case 'slotViewDisplayPercentage':
        setNewSlot({
          ...newSlot,
          inViewDisplayPercentage: e.target.value
        })
        setValue('slotViewDisplayPercentage', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      default:
        break
    }
  }

  const handleSafeframeConfigChange = e => {
    switch (e.target.name) {
      case 'slotSafeframeConfig':
        setNewSlot({
          ...newSlot,
          safeFrameConfig: e.target.checked
        })
        setIsSafeframeConfigEnable(e.target.checked)
        break
      case 'slotSandbox':
        setNewSlot({
          ...newSlot,
          safeFrameSandboxMode: e.target.checked
        })
        break
      case 'slotAllowOverlayExpansion':
        setNewSlot({
          ...newSlot,
          safeFrameAllowOverlayExpansion: e.target.checked
        })
        break
      case 'slotAllowPushExpansion':
        setNewSlot({
          ...newSlot,
          safeFrameAllowPushExpansion: e.target.checked
        })
        break
      default:
        break
    }
  }

  const toggleIsResponsive = event => {
    setNewSlot({
      ...newSlot,
      isResponsive: event.target.checked
    })
  }

  const toggleIsFluid = event => {
    if (event.target.checked) {
      setNewSlot({
        ...newSlot,
        adSizes: adUnitSizesUI.filter(adUnitSize => adUnitSize.checked),
        isFluid: event.target.checked
      })
      unregister('adUnitSizes')
      newSlot.viewportMappings.forEach(viewPort => {
        unregister(viewPort.siteViewportId)
      })
    } else {
      const _viewportMappings = newSlot.viewportMappings.map(viewport => ({
        ...viewport,
        isFluid: false
      }))
      const _viewports = viewports.map(viewport => ({
        ...viewport,
        isFluid: false
      }))
      setViewports(_viewports)
      setNewSlot({
        ...newSlot,
        adSizes: adUnitSizesUI.filter(adUnitSize => adUnitSize.checked),
        isFluid: event.target.checked,
        viewportMappings: _viewportMappings
      })
      setValue(
        'adUnitSizes',
        adUnitSizesUI.filter(adUnitSize => adUnitSize.checked),
        { shouldValidate: true }
      )
    }
  }

  const handleSlotTypeChange = event => {
    setNewSlot({ ...newSlot, slotTypeId: event.target.value })
    if (event.target.value === '') {
      setValue('slotType', '', { shouldValidate: true })
      setValue('slotLocation', '', { shouldValidate: true })
    } else {
      slotTypeChange(event.target.value)
      setValue('slotType', event.target.value, { shouldValidate: true })
      setValue('slotLocation', '', { shouldValidate: true })
    }
  }

  const slotTypeChange = slotTypeId => {
    const slotType = slotTypes.find(slotType => slotType._id === slotTypeId)
    let validLocations = []
    if (slotType?.validLocationIds.length > 0) {
      validLocations = locations.filter(location =>
        slotType.validLocationIds.includes(location._id)
      )
    }
    const newSlotName = setSlotName(
      registry,
      newSlotResource,
      slotTypes,
      validLocations
    )
    setNewSlot({
      ...newSlot,
      name: newSlotName,
      slotTypeId
    })
    setSlotLocations(validLocations)
  }

  const handleLocationChange = event => {
    locationChange(event.target.value)
  }

  const locationChange = locationId => {
    const newSlotName = setSlotName(
      registry,
      newSlotResource,
      slotTypes,
      locations
    )
    setNewSlot({
      ...newSlot,
      name: newSlotName,
      locationId
    })
    setValue('slotLocation', locationId, { shouldValidate: true })
  }

  const handleAdSizesChange = event => {
    let tempSlot = {
      ...newSlot
    }
    if (event.target.checked) {
      const selectedAdUnitSize = adUnitSizesUI.find(adUnitSize => adUnitSize.name === event.target.value)
      console.log('[Slots] ========== handleAdSizesChange::selectedAdUnitSize: ', selectedAdUnitSize)
      tempSlot.adSizes.push(selectedAdUnitSize)
      if (viewports.length > 0) {
        let _adUnitSizesUISelected = null
        const viewportMappings = viewports.map(viewport => {
          console.log('[Slots] ========== handleAdSizesChange::viewport: ', viewport)
          _adUnitSizesUISelected =
            viewport.adSizes && viewport.adSizes.length > 0
              ? viewport.adSizes.slice()
              : []
          const foundAdSizes = viewport.adSizes.find(
            adUnitSize => adUnitSize.name === event.target.value
          )
          console.log('[Slots] ========== handleAdSizesChange::foundAdSizes: ', foundAdSizes)
          if (!foundAdSizes) {
            _adUnitSizesUISelected.push(selectedAdUnitSize)
          }
          console.log('[Slots] ========== handleAdSizesChange::_adUnitSizesSelected: ', _adUnitSizesUISelected)
          return {
            ...viewport,
            adSizes: _adUnitSizesUISelected,
            isAll: false
          }
        })
        console.log('[Slots] ========== handleAdSizesChange::viewportMappings', viewportMappings)
        setViewports(viewportMappings)
        tempSlot = {
          ...tempSlot,
          viewportMappings: getViewportMappings(viewportMappings)
        }
        console.log('[Slots] ========== handleAdSizesChange::tempSlot A', tempSlot)
        setNewSlot(tempSlot)
      } else {
        setNewSlot(tempSlot)
      }
    } else {
      tempSlot.adSizes = tempSlot.adSizes.filter(
        adUnitSize => adUnitSize.name !== event.target.value
      )
      if (viewports.length > 0) {
        const viewportMappings = viewports.map(viewport => ({
          ...viewport,
        }))
        setViewports(viewportMappings)
        setNewSlot({
          ...tempSlot,
          viewportMappings: getViewportMappings(viewportMappings)
        })
      } else {
          setNewSlot(tempSlot)        
      }
    }

    // find the index of object from array that you want to update
    const selectedAdSizeIndex = adUnitSizesUI.findIndex(
      adUnitSize => adUnitSize.name === event.target.value
    )

    // make new object of updated object.
    const updatedViewport = {
      ...adUnitSizesUI[selectedAdSizeIndex],
      checked: event.target.checked
    }

    // make final new array of objects by combining updated object.
    const updatedAdUnitSizesUI = [
      ...adUnitSizesUI.slice(0, selectedAdSizeIndex),
      updatedViewport,
      ...adUnitSizesUI.slice(selectedAdSizeIndex + 1)
    ]
    console.log('[Slots] ========== handleAdSizesChange::updatedAdUnitSizesUI: ', updatedAdUnitSizesUI)
    console.log('[Slots] ========== handleAdSizesChange::adUnitSizesUI[selectedAdSizeIndex]: ', adUnitSizesUI[selectedAdSizeIndex])

    setAdUnitSizesUI(updatedAdUnitSizesUI)
    setValue(
      'adUnitSizes',
      updatedAdUnitSizesUI.filter(adUnitSize => adUnitSize.checked),
      { shouldValidate: true }
    )
  }

  const handleViewPortAdSizeChange = (event, viewPort, adSize) => {
    // find the index of object from array that you want to update
    const selectedViewportIndex = viewports.findIndex(
      _viewport => _viewport.siteViewportId === viewPort.siteViewportId
    )

    // update viewports adSizes checked status
    const updatedAdSizes = viewports
      .find(_viewport => _viewport.siteViewportId === viewPort.siteViewportId)
      .adSizes.map(_adSize => ({
        ..._adSize,
        checked:
          _adSize.name === adSize.name ? event.target.checked : _adSize.checked
      }))
    // make counts of all adSizes and checked adSizes
    const checkboxCount = viewports[selectedViewportIndex].adSizes?.length
    const checkedAdSize = updatedAdSizes.filter(adSize => adSize.checked)

    // make new object of updated object.
    const updatedViewport = {
      ...viewports[selectedViewportIndex],
      adSizes: updatedAdSizes,
      isAll: checkboxCount === checkedAdSize?.length
    }

    // make final new array of objects by combining updated object.
    const updatedViewports = [
      ...viewports.slice(0, selectedViewportIndex),
      updatedViewport,
      ...viewports.slice(selectedViewportIndex + 1)
    ]
    setViewports(updatedViewports)
    setNewSlot({
      ...newSlot,
      adSizes: updatedAdSizes,
      viewportMappings: getViewportMappings(updatedViewports)
    })
    setValue(
      viewPort.siteViewportId,
      updatedAdSizes.filter(adUnitSize => adUnitSize.checked),
      { shouldValidate: true }
    )
  }

  const getViewportMappings = viewports => {
    const viewportMappings = []
    viewports.forEach(viewport => {
      const _adSizes = viewport.adSizes.filter(
        adSize => adSize.checked === true
      )
      const selectedViewportIndex = viewports.findIndex(
        _viewport => _viewport.siteViewportId === viewport.siteViewportId
      )
      const updatedViewport = {
        ...viewports[selectedViewportIndex],
        adSizes: _adSizes
      }
      viewportMappings.push(updatedViewport)
    })
    return viewportMappings
  }

  const handleAllSizesCheck = (event, viewport) => {
    // find the index of object from array that you want to update
    const selectedViewportIndex = viewports.findIndex(
      _viewport => _viewport.siteViewportId === viewport.siteViewportId
    )

    // update viewports adSizes checked status
    const updatedAdSizes = viewports
      .find(_viewport => _viewport.siteViewportId === viewport.siteViewportId)
      .adSizes.map(adUnitSize => ({
        ...adUnitSize,
        checked: !!event.target.checked
      }))

    // make new object of updated object.
    const updatedViewport = {
      ...viewports[selectedViewportIndex],
      adSizes: updatedAdSizes,
      isAll: event.target.checked
    }

    // make final new array of objects by combining updated object.
    const updatedViewports = [
      ...viewports.slice(0, selectedViewportIndex),
      updatedViewport,
      ...viewports.slice(selectedViewportIndex + 1)
    ]
    setViewports(updatedViewports)
    setNewSlot({
      ...newSlot,
      viewportMappings: getViewportMappings(updatedViewports)
    })
    setValue(
      viewport.siteViewportId,
      updatedAdSizes.filter(adUnitSize => adUnitSize.checked),
      { shouldValidate: true }
    )
  }

  const handleSuppressedChange = (event, viewport) => {
    // find the index of object from array that you want to update
    const selectedViewportIndex = viewports.findIndex(
      _viewport => _viewport.siteViewportId === viewport.siteViewportId
    )
    const isFluidChecked = viewports[selectedViewportIndex].isFluid
    const isSuppressedChecked = viewports[selectedViewportIndex].isSuppressed
    // make new object of updated object.
    const updatedViewport = {
      ...viewports[selectedViewportIndex],
      isSuppressed: event.target.checked,
      isFluid: !isFluidChecked ? false : isFluidChecked && isSuppressedChecked
    }

    // make final new array of objects by combining updated object.
    const updatedViewports = [
      ...viewports.slice(0, selectedViewportIndex),
      updatedViewport,
      ...viewports.slice(selectedViewportIndex + 1)
    ]
    setViewports(updatedViewports)
    setNewSlot({
      ...newSlot,
      viewportMappings: getViewportMappings(updatedViewports)
    })
    if (event.target.checked) {
      unregister(viewport.siteViewportId)
    }
  }

  const handleFluidChange = (event, viewport) => {
    // find the index of object from array that you want to update
    const selectedViewportIndex = viewports.findIndex(
      _viewport => _viewport.siteViewportId === viewport.siteViewportId
    )
    const isFluidChecked = viewports[selectedViewportIndex].isFluid
    const isSuppressedChecked = viewports[selectedViewportIndex].isSuppressed
    // make new object of updated object.
    const updatedViewport = {
      ...viewports[selectedViewportIndex],
      isFluid: event.target.checked,
      isSuppressed: !isSuppressedChecked
        ? false
        : isFluidChecked && isSuppressedChecked
    }

    // make final new array of objects by combining updated object.
    const updatedViewports = [
      ...viewports.slice(0, selectedViewportIndex),
      updatedViewport,
      ...viewports.slice(selectedViewportIndex + 1)
    ]
    setViewports(updatedViewports)
    setNewSlot({
      ...newSlot,
      viewportMappings: getViewportMappings(updatedViewports)
    })
  }

  const viewPortHelperText = viewport => {
    if (newSlot.isFluid) {
      if (
        viewport.isFluid ||
        viewport.isSuppressed ||
        viewport.adSizes.filter(v => v.checked).length >= 1
      ) {
        return <></>
      } else {
        return (
          <FormHelperText error>
            Ad Sizes/Fluid is required for Fluid Slot.
          </FormHelperText>
        )
      }
    } else {
      if (
        !(viewport.isSuppressed || viewport.isFluid) &&
        !!errors[viewport.siteViewportId]
      ) {
        return (
          <FormHelperText error>
            Ad Sizes are required for non-suppressed slots
          </FormHelperText>
        )
      } else {
        return <></>
      }
    }
  }

  const viewPortError = viewport => {
    if (newSlot.isFluid) {
      if (
        viewport.isFluid ||
        viewport.isSuppressed ||
        viewport.adSizes.filter(v => v.checked).length >= 1
      ) {
        return false
      } else {
        return true
      }
    } else {
      if (
        !(viewport.isSuppressed || viewport.isFluid) &&
        !!errors[viewport.siteViewportId]
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const selectSlotType = slot => {
    if (slotTypes.length > 0) {
      const slotType = slotTypes.find(
        slotType => slotType._id === slot.slotTypeId
      )
      return slotType ? slotType.name : ''
    } else {
      return ''
    }
  }

  const selectLocation = slot => {
    if (locations.length > 0) {
      const location = locations.find(
        location => location._id === slot.locationId
      )
      return location ? location.name : ''
    } else {
      return ''
    }
  }

  const initialViewportState = site => {
    if (site) {
      if (site.viewports && site.viewports.length > 0) {
        const _viewports = site.viewports
          .slice()
          .sort((a, b) => b.minWidth - a.minWidth)

        const viewportMappings = _viewports.map(viewport => ({
          ...viewport,
          isFluid: false,
          isSuppressed: false,
          status: null,
          adSizes: [],
          isAll: false
        }))
        setViewports(viewportMappings)
        const newSlotName = setSlotName(
          registry,
          newSlotResource,
          slotTypes,
          locations
        )
        setNewSlot({
          ...newSlotResource,
          adSizes: [],
          name: newSlotName,
          viewportMappings,
          targetings:
            isNew && newSlot?.targetings?.length === 0
              ? [{ key: 'pos', values: [{ name: newSlot?.name?.split(/_(.+)/)[1] }] }]
              : []
        })
        setValue('slotType', '')
        setValue('slotLocation', '')
        _viewports.forEach(viewport => {
          setValue(viewport.siteViewportId, [])
        })
      } else {
        const newSlotName = setSlotName(
          registry,
          newSlotResource,
          slotTypes,
          locations
        )
        setNewSlot({
          ...newSlotResource,
          adSizes: [],
          name: newSlotName
        })
        setValue('slotType', '')
        setValue('slotLocation', '')
      }
    }
  }

  const handleCustomTargetingForRegistrySave = bulkTargetings => {
    if (bulkTargetings) {
      const finalTargeting = []
      if (newSlot.targetings && newSlot.targetings.length > 0) {
        for (let y = 0; y < newSlot.targetings.length; y++) {
          const target = newSlot.targetings[y]
          if (
            Array.isArray(bulkTargetings[target.key]) &&
            bulkTargetings[target.key].length > 0
          ) {
            if (Array.isArray(target.value)) {
              for (let z = 0; z < target.value.length; z++) {
                bulkTargetings[target.key].push(target.value[z])
              }
            } else {
              bulkTargetings[target.key].push(target.value)
            }
          } else {
            if (Array.isArray(target.value)) {
              bulkTargetings[target.key] = target.value
            } else {
              bulkTargetings[target.key] = [target.value]
            }
          }
        }
      }
      bulkTargetings.forEach(bulkTarget => {
        if (bulkTarget.key !== undefined) {
          bulkTarget.values.forEach(value => {
            const targetingObject = {
              key: bulkTarget.key,
              value: value.name
            }
            finalTargeting.push(targetingObject)
          })
        }
      })
      setNewSlot({
        ...newSlot,
        targetings: finalTargeting
      })
    }
  }

  useEffect(() => {
    if (newSlot.name && (newSlot.slotTypeId || newSlot.locationId)) {
      const newSlotName = setSlotName(registry, newSlot, slotTypes, locations)
      if (newSlotName !== undefined) {
        setNewSlot({
          ...newSlot,
          name: newSlotName
        })
      }
    }
  }, [newSlot.slotTypeId, newSlot.locationId])

  useEffect(() => {
    if (registry) {
      setCurrentRegistry(registry)
    }
    if (slots) {
      if (isCopyRegistry) {
        const _slots = JSON.parse(JSON.stringify(slots))
        if (registry.isSingleton) {
          _slots[0].name = ''
          _slots[0].name = setSlotName(
            registry,
            _slots[0],
            slotTypes,
            locations
          )
          setRegistrySlots(_slots.map(Slot => ({ ...Slot })))
        } else {
          setRegistrySlots(slots.map(Slot => ({ ...Slot })))
        }
        dispatch(ToggleCopyRegistryStatus())
        // ToggleCopyRegistryStatus
      } else {
        if (isSingleton && slots.length > 0) {
          const _slot = []
          _slot.push(JSON.parse(JSON.stringify(slots[0])))
          setRegistrySlots(_slot.map(Slot => ({ ...Slot })))
        } else {
          setRegistrySlots(slots.map(Slot => ({ ...Slot })))
        }
      }
    }
    initialViewportState(site)
  }, [registry, slots, site])

  useEffect(() => {
    if (newSlot.locationId && slotLocations) {
      locationChange(newSlot.locationId)
    }
  }, [slotLocations])

  useEffect(() => {
    if (newSlotResource) {
      if (isCopy) {
        const rootAdUnit = adUnitDictionary[site.rootAdUnitId]
        let adUnit = rootAdUnit
        const adUnitPathNames = newSlotResource.adUnitPath.split('/')
        let i = 1
        // loop to find selected last Child in Ad Unit
        while (i < adUnitPathNames.length) {
          if (!adUnit.hasChildren) {
            break
          }
          adUnit = adUnit.children.find(
            child => child.name === adUnitPathNames[i]
          )
          if (!adUnit) {
            break
          }
          i++
        }
        let adSizes = []
        if (newSlotResource.isFluid && newSlotResource.adSizes.length === 0) {
          adSizes = adUnit?.adUnitSizes?.slice().map(item => ({
            ...item,
            checked: false,
            name: item.width + 'x' + item.height
          }))
        } else {
          adSizes = adUnit?.adUnitSizes?.slice().map(item => ({
            ...item,
            checked: newSlotResource.adSizes.some(
              obj => obj.width + 'x' + obj.height === item.name
            ),
            name: item.width + 'x' + item.height
          }))
        }
        setAdUnitSizesUI(adSizes)
        if (newSlotResource.viewportMappings.length > 0) {
          const viewportMappings = []
          newSlotResource.viewportMappings.forEach(item => {
            const viewportMapping = viewports.find(
              _viewport => _viewport.siteViewportId === item.siteViewportId
            )
            const newSlotAdSizes = newSlotResource.adSizes
              .slice()
              .map(item => ({ ...item, checked: false }))

            const copiedAdSizes = item.adSizes.map(item => ({
              checked: true,
              name: item.width + 'x' + item.height,
              ...item
            }))

            const resultAdSizes = newSlotAdSizes
              .filter(el => {
                return !copiedAdSizes.some(f => {
                  return f.width === el.width && f.height === el.height
                })
              })
              .map(item => ({
                checked: false,
                name: item.width + 'x' + item.height,
                ...item
              }))
            const viewportAdSizes = [...copiedAdSizes, ...resultAdSizes]
            viewportMappings.push({
              name: viewportMapping.name,
              minHeight: viewportMapping.minHeight,
              minWidth: viewportMapping.minWidth,
              isFluid: item.isFluid,
              isSuppressed: item.isSuppressed,
              siteViewportId: item.siteViewportId,
              status: item.status,
              adSizes: viewportAdSizes,
              isAll: viewportAdSizes.length === newSlotAdSizes.length
            })
          })
          const _viewports = viewportMappings
            .slice()
            .sort((a, b) => b.minWidth - a.minWidth)
          setViewports(_viewports)
        }
        // This sets slot type
        setValue('slotType', newSlotResource.slotTypeId, {
          shouldValidate: true
        })

        // This sets slot location provided slotTypeId
        if (newSlotResource.slotTypeId) {
          const slotType = slotTypes.find(
            slotType => slotType._id === newSlotResource.slotTypeId
          )
          let validLocations = []
          if (slotType?.validLocationIds.length > 0) {
            validLocations = locations.filter(location =>
              slotType.validLocationIds.includes(location._id)
            )
          }
          setSlotLocations(validLocations)
        }
        const newSlotName = setSlotName(
          registry,
          newSlotResource,
          slotTypes,
          locations
        )
        setNewSlot({
          ...newSlotResource,
          name: newSlotName
        })
        setIsCopy(false)
      } else {
        const adSizes = []
        newSlotResource.adSizes.forEach(adUnitSize => {
          const adSize = {
            checked: false,
            height: adUnitSize.height,
            width: adUnitSize.width,
            name: adUnitSize.width + 'x' + adUnitSize.height
          }
          adSizes.push(adSize)
        })
        initialViewportState(site)
        setAdUnitSizesUI(adSizes)
      }
      setIsViewRefreshSlot(
        isViewRefresh ? false : newSlotResource?.hasInViewRefresh
      )
    }
  }, [newSlotResource, site])

  useEffect(() => {
    const _viewports = viewports
      ?.slice()
      .sort((a, b) => b.minWidth - a.minWidth)
    if (newSlot.isFluid) {
      if (_viewports && _viewports.length > 0) {
        _viewports.forEach(viewport => {
          if (!viewport.isFluid) {
            setValue(
              'isFluid' + viewport.siteViewportId,
              viewport.adSizes.filter(adSize => adSize.checked),
              { shouldValidate: true }
            )
          }
        })
      }
    } else if (adUnitSizesUI && adUnitSizesUI.length > 0) {
      if (_viewports && _viewports.length > 0) {
        _viewports.forEach(viewport => {
          unregister('isFluid' + viewport.siteViewportId)
          if (!(viewport.isSuppressed || viewport.isFluid)) {
            setValue(
              viewport.siteViewportId,
              viewport.adSizes.filter(adSize => adSize.checked),
              { shouldValidate: true }
            )
          }
        })
      }
    }
    setValue(
      'adUnitSizes',
      adUnitSizesUI.filter(adUnitSize => adUnitSize.checked)
    )
  }, [adUnitSizesUI, viewports, newSlot])

  useEffect(() => {
    if (currentRegistry.length > 0) {
      setCurrentRegistry({ ...currentRegistry, hasInViewRefresh: isViewRefresh })
    }
    // setIsViewRefreshSlot(newSlotResource?.hasInViewRefresh)
    setValue('slotViewRefreshCount', 5, { shouldValidate: true })
    setValue('slotViewRefreshInterval', 35, { shouldValidate: true })
  }, [isViewRefresh])

  useEffect(() => {
    if (currentRegistry.length > 0) {
      setCurrentRegistry({
        ...currentRegistry,
        safeFrameConfigs: isSafeFrameConfig
      })
    }
    // setNewSlot({ ...newSlot, safeFrameSandboxMode: isSafeFrameConfig })
  }, [isSafeFrameConfig])

  const sizeMapping = slot => {
    if (viewports && viewports.length > 0) {
      return (
        <Grid container spacing={2}>
          {viewports.map((viewport, i) => (
            <Grid key={i} item xs={6}>
              <Box
                border={viewPortError(viewport) ? 1 : 0}
                borderColor={viewPortError(viewport) ? 'error.main' : ''}
              >
                <Paper
                  elevation={1}
                  className={[classes.paper, commonClasses.p_1].join(' ')}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6} className={commonClasses.pl_1}>
                      {viewport.name}
                    </Grid>
                    <Grid item xs={6} className={commonClasses.pr_1}>
                      <span className={classes.itemRight}>
                        [{viewport.minHeight}-{viewport.minWidth}]
                      </span>
                    </Grid>
                    <Grid item xs={12} className={classes.itemCenter}>
                      {viewport.adSizes.length > 0 && !viewport.isSuppressed
                        ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                viewport.adSizes.filter(e => e.checked === true)
                                  .length === viewport.adSizes.length
                              }
                                value={viewport.isAll}
                                indeterminate={
                                viewport.adSizes.filter(e => e.checked === true)
                                  .length > 0 &&
                                viewport.adSizes.filter(e => e.checked === true)
                                  .length !== viewport.adSizes.length
                              }
                                onChange={e => {
                                  handleAllSizesCheck(e, viewport)
                                }}
                                color='primary'
                              />
                          }
                            label='All Sizes'
                          />
                          )
                        : (
                          <></>
                          )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={e => handleSuppressedChange(e, viewport)}
                            checked={viewport.isSuppressed}
                            value={viewport.isSuppressed}
                            color='primary'
                          />
                        }
                        label='Suppressed'
                      />
                      {newSlot.isFluid
                        ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={viewport.isFluid}
                                {...register(viewport.siteViewportId, {
                                  validate: validateFluid
                                })}
                                onChange={e => handleFluidChange(e, viewport)}
                                value={viewport.isFluid}
                                color='primary'
                              />
                          }
                            label='Fluid'
                          />
                          )
                        : (
                          <></>
                          )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                      <FormGroup row>
                        {viewportAdSizesMapping(viewport)}
                      </FormGroup>
                      {viewPortHelperText(viewport)}
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          ))}
        </Grid>
      )
    } else {
      return <></>
    }
  }

  const viewportAdSizesMapping = viewport => {
    if (viewport.adSizes.length > 0 && !viewport.isSuppressed) {
      return viewport.adSizes.map((adSize, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={adSize.checked}
              value={adSize.name}
              color='primary'
              {...register(viewport.siteViewportId, {
                validate: () => {
                  const values = getValues()
                  if (typeof values.adUnitSizes === 'object') {
                    if (typeof values[viewport.siteViewportId] === 'object') {
                      if (!viewport.isFluid) {
                        return (
                          values[viewport.siteViewportId].filter(v => Boolean(v))
                            .length >= 1 ||
                          'Ad Sizes are required for non-suppressed slots.'
                        )
                      } else {
                        return true
                      }
                    }
                  }
                }
              })}
              onChange={e => handleViewPortAdSizeChange(e, viewport, adSize)}
            />
          }
          label={adSize.name}
        />
      ))
    } else {
      return <></>
    }
  }

  const deleteButton = slot => {
    if (isDeleting && slot._id === selectedItemId) {
      return (
        <IconButton aria-label='Removing...' title='Removing...'>
          <CircularProgress size={24} />
        </IconButton>
      )
    } else {
      return (
        <IconButton
          aria-label='Remove'
          disabled={isRegistryChanged}
          title='Remove'
          className={commonClasses.btnDanger}
          onClick={() => {
            onDeleteSlot(slot)
          }}
        >
          <DeleteIcon />
        </IconButton>
      )
    }
  }
  console.log('[Slots] ========== render::currentRegistry.hasInViewRefresh/isViewRefresh', { has: currentRegistry.hasInViewRefresh, is: currentRegistry.isViewRefresh })

  return (
    <div className='registrySlots'>
      <Toast
        isOpen={toast.isOpen}
        variant={toast.variant}
        message={toast.message}
        onClose={handleCloseMessage}
      />
      <Dialog
        onClose={() => dispatch(ToggleIsNewStatus())}
        aria-labelledby='Add New Slot'
        open={isNew}
        maxWidth='md'
        fullScreen={fullScreen}
      >
        <DialogTitle id='form-dialog-title'>Add New Slot</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid container className={commonClasses.m_1}>
              <Grid item xs={12} sm={12}>
                <Grid container className={commonClasses.m_1}>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      label='Ad Slot'
                      margin='dense'
                      id='slotName'
                      name='slotName'
                      variant='standard'
                      value={newSlot.name ? newSlot.name : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {adUnitFields(newSlot)}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={3}>
                        <FormControl
                          style={{ width: '70%' }}
                          className={classes.formControl}
                        >
                          <InputLabel id='select-slotTypes'>
                            Slot Type
                          </InputLabel>
                          <Select
                            {...register('slotType', {
                              validate: value => value !== ''
                            })}
                            labelId='select-slotTypes'
                            style={{ width: '99%', marginTop: '16px' }}
                            id='slotType'
                            value={newSlot.slotTypeId ? newSlot.slotTypeId : ''}
                            onChange={e => handleSlotTypeChange(e)}
                            error={!!errors.slotType}
                          >
                            <MenuItem key={-1} value='' />
                            {slotTypes.map(adUnit => (
                              <MenuItem key={adUnit._id} value={adUnit._id}>
                                {adUnit.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!errors.slotType && (
                            <FormHelperText error={!!errors.slotType}>
                              Required
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl
                          style={{ width: '70%' }}
                          className={classes.formControl}
                        >
                          <InputLabel id='select-locations'>
                            Location
                          </InputLabel>
                          <Select
                            {...register('slotLocation', {
                              validate: value => value !== ''
                            })}
                            labelId='select-locations'
                            style={{ width: '99%', marginTop: '16px' }}
                            id='slotLocation'
                            value={newSlot.locationId ? newSlot.locationId : ''}
                            onChange={e => handleLocationChange(e)}
                            error={!!errors.slotLocation}
                          >
                            <MenuItem key={-1} value='' />
                            {slotLocations.map(location => (
                              <MenuItem key={location._id} value={location._id}>
                                {location.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!errors.slotLocation && (
                            <FormHelperText error={!!errors.slotLocation}>
                              Required
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container className={commonClasses.m_1}>
                      <Grid item xs={1}>
                        <InputLabel
                          id='lbl-adUnitSizes'
                          className={commonClasses.mt_2}
                        >
                          Ad Sizes
                        </InputLabel>
                      </Grid>
                      <Grid item xs={10}>
                        <Box
                          border={errors.adUnitSizes ? 1 : 0}
                          borderColor={errors.adUnitSizes ? 'error.main' : ''}
                        >
                          <FormGroup aria-label='adUnitSizes' row>
                            {adUnitSizesUI.length > 0
                              ? (
                                  adUnitSizesUI.map((adUnitSize, index) => (
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          {...register('adUnitSizes', {
                                            validate: validateAdUnitSizes
                                          })}
                                          checked={adUnitSize.checked}
                                          value={
                                        adUnitSize.width +
                                        'x' +
                                        adUnitSize.height
                                      }
                                          onChange={e => {
                                            handleAdSizesChange(e)
                                          }}
                                          color='primary'
                                        />
                                  }
                                      label={
                                    adUnitSize.width + 'x' + adUnitSize.height
                                  }
                                    />
                                  ))
                                )
                              : (
                                <></>
                                )}
                          </FormGroup>
                          {!!errors.adUnitSizes && (
                            <FormHelperText error={!!errors.adUnitSizes}>
                              Ad Sizes are required.
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!currentRegistry.hasInViewRefresh ? (
                    <Grid container key={'inViewRefresh'}>
                      <Grid item md={2} xs={2} key={'ivr-toggle'}>
                        <ToggleSwitch
                          label='In-View Refresh'
                          name='registryInViewRefresh'
                          offLabel='Disabled'
                          onLabel='Enabled'
                          checked={!!newSlot.hasInViewRefresh}
                          onChange={e => {
                            toggleInViewRefresh(e)
                          }}
                          value={!!newSlot.hasInViewRefresh}
                        />
                      </Grid>
                      <Grid item md={10} xs={10} key={'ivr-configuration'}>
                        {!currentRegistry.hasInViewRefresh
                          ? (
                            <Grid container>
                              <Grid item md={3} xs={3} key={'refresh-count'}>
                                {newSlot.hasInViewRefresh
                                  ? (
                                    <>
                                      <Paper className={classes.ConfigurationItems}>
                                        <TextField
                                          {...register('slotViewRefreshCount')}
                                          className={classes.input}
                                          label='Refresh Count'
                                          margin='dense'
                                          id='slotViewRefreshCount'
                                          variant='standard'
                                          onChange={e => {
                                            handleInViewRefreshChange(e)
                                          }}
                                          value={
                                            newSlot.inViewRefreshCount === 0
                                              ? site.inViewRefreshCount
                                              : newSlot.inViewRefreshCount
                                          }
                                          type='number'
                                          InputProps={{
                                            inputProps: { min: '5', step: '1' }
                                          }}
                                          error={!!errors.slotViewRefreshCount}
                                          helperText={
                                            errors.slotViewRefreshCount
                                              ? errors.slotViewRefreshCount.message
                                              : ''
                                          }
                                        />
                                        <Divider
                                          className={commonClasses.verticalDivider28}
                                          orientation='vertical'
                                        />
                                        <span className={classes.smallFont}>times</span>
                                      </Paper>
                                    </>
                                  )
                                  : (
                                    <></>
                                  )
                                }
                              </Grid>
                              <Grid item md={3} xs={3} key={'refresh-interval'}>
                                {newSlot.hasInViewRefresh
                                  ? (
                                    <>
                                      <Paper className={classes.ConfigurationItems}>
                                        <TextField
                                          {...register('slotViewRefreshInterval')}
                                          className={classes.input}
                                          label='Refresh Interval'
                                          margin='dense'
                                          id='slotViewRefreshInterval'
                                          variant='standard'
                                          type='number'
                                          InputProps={{
                                            inputProps: { min: '35', step: '1' }
                                          }}
                                          onChange={e => {
                                            handleInViewRefreshChange(e)
                                          }}
                                          value={
                                            newSlot.inViewRefreshInterval === 0
                                              ? site.inViewRefreshInterval
                                              : newSlot.inViewRefreshInterval
                                          }
                                          error={!!errors.slotViewRefreshInterval}
                                          helperText={
                                            errors.slotViewRefreshInterval
                                              ? errors.slotViewRefreshInterval.message
                                              : ''
                                          }
                                        />
                                        <Divider
                                          className={commonClasses.verticalDivider28}
                                          orientation='vertical'
                                        />
                                        <span className={classes.smallFont}>seconds</span>
                                      </Paper>
                                    </>
                                  )
                                  : (
                                    <></>
                                  )
                                }
                              </Grid>
                            </Grid>
                            )
                          : (
                            <></>
                            )}
                      </Grid>
                    </Grid>
                  ): ''}

                  {/* {currentRegistry.hasInViewRefresh
                    ? (<></>)
                    : (
                      <>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item md={4} xs={4}>
                              {isViewRefresh
                                ? (
                                  <ToggleSwitch
                                    label='InView Refresh'
                                    name='slotInViewRefresh'
                                    offLabel='Disabled'
                                    onLabel='Enabled'
                                    checked={false}
                                    onChange={e => {
                                      toggleInViewRefresh(e)
                                    }}
                                    value={false}
                                    inLine
                                    disabled
                                  />
                                  )
                                : (
                                  <ToggleSwitch
                                    label='InView Refresh'
                                    name='slotInViewRefresh'
                                    offLabel='Disabled'
                                    onLabel='Enabled'
                                    checked={newSlot.hasInViewRefresh}
                                    onChange={e => {
                                      toggleInViewRefresh(e)
                                    }}
                                    value={newSlot.hasInViewRefresh}
                                    inLine
                                  />
                                  )}
                            </Grid>
                            <Grid item md={4} xs={4}>
                              {isViewRefreshSlot
                                ? (
                                  <>
                                    <Paper className={classes.root}>
                                      <TextField
                                        {...register('slotViewRefreshCount', {
                                          validate: validateInViewRefreshCount
                                        })}
                      // {...register('slotViewRefreshCount')}
                                        className={classes.input}
                                        label='Refresh Count'
                                        margin='dense'
                                        id='slotViewRefreshCount'
                                        variant='standard'
                                        defaultValue={
                        parseInt(newSlot.inViewRefreshCount) === 0
                          ? 5
                          : parseInt(newSlot.inViewRefreshCount)
                      }
                                        onChange={e => {
                                          handleInViewRefreshChange(e)
                                        }}
                                        type='number'
                                        InputProps={{
                                          inputProps: { min: '5', step: '1' }
                                        }}
                                        error={!!errors.slotViewRefreshCount}
                                        helperText={
                        errors.slotViewRefreshCount
                          ? errors.slotViewRefreshCount.message
                          : ''
                      }
                                      />
                                      <Divider
                                        className={commonClasses.verticalDivider28}
                                        orientation='vertical'
                                      />
                                      times
                                    </Paper>
                                  </>
                                  )
                                : (
                                  <></>
                                  )}
                            </Grid>
                            <Grid item md={4} xs={4}>
                              {isViewRefreshSlot
                                ? (
                                  <>
                                    <Paper className={classes.root}>
                                      <TextField
                                        {...register('slotViewRefreshInterval', {
                                          validate: validateInViewRefreshInterval
                                        })}
                                        className={classes.input}
                                        label='Refresh Interval'
                                        margin='dense'
                                        id='slotViewRefreshInterval'
                                        variant='standard'
                                        type='number'
                                        InputProps={{
                                          inputProps: { min: '35', step: '1' }
                                        }}
                                        defaultValue={
                        parseInt(newSlot.inViewRefreshInterval) === 0
                          ? 35
                          : parseInt(newSlot.inViewRefreshInterval)
                      }
                                        onChange={e => {
                                          handleInViewRefreshChange(e)
                                        }}
                                        error={!!errors.slotViewRefreshInterval}
                                        helperText={
                        errors.slotViewRefreshInterval
                          ? errors.slotViewRefreshInterval.message
                          : ''
                      }
                                      />
                                      <Divider
                                        className={commonClasses.verticalDivider28}
                                        orientation='vertical'
                                      />
                                      seconds
                                    </Paper>
                                  </>
                                  )
                                : (
                                  <></>
                                  )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                      )} */}

                  {currentRegistry.safeFrameConfig
                    ? (<></>)
                    : (
                      <>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item md={4} xs={4}>
                              <ToggleSwitch
                                label='Set Safeframe Config'
                                name='slotSafeframeConfig'
                                offLabel='Disabled'
                                onLabel='Enabled'
                                checked={
                                  newSlot.safeFrameConfig !== undefined
                                    ? !!newSlot.safeFrameConfig
                                    : false
                                }
                                onChange={e => handleSafeframeConfigChange(e)}
                                value={newSlot.safeFrameConfig}
                                inLine
                              />
                            </Grid>
                            <Grid item md={8} xs={8}>
                              {isSafeframeConfigEnable
                                ? (
                                  <>
                                    <ToggleSwitch
                                      label='Sandbox'
                                      name='slotSandbox'
                                      offLabel='Yes'
                                      onLabel='No'
                                      checked={
                                      newSlot.safeFrameSandboxMode !== undefined
                                        ? !!newSlot.safeFrameSandboxMode
                                        : false
                                    }
                                      onChange={e => handleSafeframeConfigChange(e)}
                                      value={newSlot.safeFrameSandboxMode}
                                      inLine
                                    />
                                    <ToggleSwitch
                                      label='Allow Overlay Expansion'
                                      name='slotAllowOverlayExpansion'
                                      offLabel='Yes'
                                      onLabel='No'
                                      checked={
                                      newSlot.safeFrameAllowOverlayExpansion !==
                                      undefined
                                        ? !!newSlot.safeFrameAllowOverlayExpansion
                                        : false
                                    }
                                      onChange={e => handleSafeframeConfigChange(e)}
                                      value={newSlot.safeFrameAllowOverlayExpansion}
                                      inLine
                                    />
                                    <ToggleSwitch
                                      label='Allow Push Expansion'
                                      name='slotAllowPushExpansion'
                                      offLabel='Yes'
                                      onLabel='No'
                                      checked={
                                      newSlot.safeFrameAllowPushExpansion !==
                                      undefined
                                        ? !!newSlot.safeFrameAllowPushExpansion
                                        : false
                                    }
                                      onChange={e => handleSafeframeConfigChange(e)}
                                      value={newSlot.safeFrameAllowPushExpansion}
                                      inLine
                                    />
                                  </>
                                  )
                                : (
                                  <></>
                                  )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                      )}
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2}>
                        { site?.viewports?.length > 0 ? (
                          <ToggleSwitch
                            label='Responsive'
                            name='responsive'
                            offLabel='No'
                            onLabel='Yes'
                            checked={!!newSlot.isResponsive}
                            onChange={e => toggleIsResponsive(e)}
                            value={newSlot.isResponsive}
                            inLine
                          />
                        ): (<></>) }
                        <ToggleSwitch
                          label='Is Slot Fluid?'
                          name='isSlotFluid'
                          offLabel='No'
                          onLabel='Yes'
                          checked={!!newSlot.isFluid}
                          onChange={e => toggleIsFluid(e)}
                          value={newSlot.isFluid}
                          inLine
                        />
                      </Grid>
                      <Grid item xs={10}>
                        {newSlot.isResponsive
                          ? (
                            <Card elevation={2} className={commonClasses.mb_2}>
                              <Typography className={commonClasses.m_1}>
                                Size Mappings:
                              </Typography>
                              <Divider />
                              <CardContent>{sizeMapping(newSlot)}</CardContent>
                            </Card>
                            )
                          : (
                            <></>
                            )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <CustomTargetingAccordion
                          currentTargetings={
                            isNew && newSlot?.targetings?.length === 0
                              ? [{ key: 'pos', values: [{ name: newSlot?.name?.split(/_(.+)/)[1] }] }]
                              : newSlot?.targetings
                          }
                          isSlotTargeting
                          slotName={newSlot?.name}
                          handleSave={handleCustomTargetingForRegistrySave}
                          isNew={isNew}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading
            ? (
              <Button
                disabled
                variant='contained'
                title='Saving...'
                className={commonClasses.btnSuccess}
                startIcon={<CircularProgress size={24} />}
              >
                Saving
              </Button>
              )
            : (
              <Button
                autoFocus
                className={commonClasses.btnSuccess}
                onClick={handleSubmit(AddNewSlot)}
                variant='contained'
                startIcon={<CheckCircleIcon />}
              >
                Save
              </Button>
              )}
          <Button
            className={commonClasses.ml_1}
            title='Cancel'
            variant='contained'
            onClick={() => {
              unregisterValidation()
              dispatch(ToggleIsNewStatus())
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Paper position='static'>
        <form autoComplete='off' noValidate>
          <CardHeader title='Slots' />
          <Divider />
          <CardContent>
            {isSingleton && currentRegistry.slots?.length >= 1
              ? (
                <></>
                )
              : (
                <Grid container style={{ marginBottom: '10pt' }}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      style={{ width: '99%' }}
                      label='Find'
                      margin='dense'
                      name='itemFilter'
                      defaultValue={searchTerm}
                      variant='outlined'
                      onChange={onSearchChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      disabled={isRegistryChanged || loading}
                      variant='contained'
                      color='primary'
                      className={[
                        commonClasses.btnSuccess,
                        commonClasses.m_1
                      ].join(' ')}
                      startIcon={<Add />}
                      onClick={() => onAddSlot()}
                    >
                      Add New Slot
                    </Button>
                  </Grid>
                  <Grid item md={2} />
                </Grid>
                )}

            <Divider />
            <Grid>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                      Name
                    </TableCell>
                    <TableCell style={{ width: '20%', fontWeight: 'bold' }}>
                      Ad Unit
                    </TableCell>
                    <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                      Slot Type
                    </TableCell>
                    <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                      Location
                    </TableCell>
                    <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                      Is Slot Fluid
                    </TableCell>
                    <TableCell style={{ width: '20%', fontWeight: 'bold' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registrySlots.length > 0
                    ? (
                        registrySlots.map((slot, index) => {
                          if (isSingleton) {
                            return (
                              <TableRow
                                name='siteRow'
                                key={slot._id ? slot._id : index}
                                hover
                              >
                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {slot.name}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {slot.adUnitPath}
                                  </div>
                                </TableCell>

                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {selectSlotType(slot)}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {selectLocation(slot)}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {slot.isFluid ? 'Yes' : 'No'}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    className={addClasses(
                                      commonClasses.btnSuccess,
                                      commonClasses.mr_1
                                    )}
                                    disabled={isRegistryChanged || loading}
                                    aria-label='Edit'
                                    title='Edit'
                                    onClick={() => onEditSlot(slot)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  {isSingleton
                                    ? (
                                      <></>
                                      )
                                    : (
                                      <>
                                        <IconButton
                                          disabled={isRegistryChanged || loading}
                                          aria-label='Copy'
                                          title='Copy'
                                          className={[
                                            commonClasses.btnSuccess,
                                            commonClasses.mr_1
                                          ].join(' ')}
                                          onClick={() => {
                                            onCopySlot(slot)
                                          }}
                                        >
                                          <FileCopyIcon />
                                        </IconButton>
                                        {deleteButton(slot)}
                                      </>
                                      )}
                                </TableCell>
                              </TableRow>
                            )
                          } else {
                            return (
                              <TableRow
                                name='siteRow'
                                key={slot._id ? slot._id : index}
                                hover
                              >
                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {slot.name}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {slot.adUnitPath}
                                  </div>
                                </TableCell>

                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {selectSlotType(slot)}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {selectLocation(slot)}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={commonClasses.plainText}>
                                    {slot.isFluid ? 'Yes' : 'No'}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    className={addClasses(
                                      commonClasses.btnSuccess,
                                      commonClasses.mr_1
                                    )}
                                    disabled={isRegistryChanged || loading}
                                    aria-label='Edit'
                                    title='Edit'
                                    onClick={() => onEditSlot(slot)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  {isSingleton
                                    ? (
                                      <></>
                                      )
                                    : (
                                      <>
                                        <IconButton
                                          disabled={isRegistryChanged || loading}
                                          aria-label='Copy'
                                          title='Copy'
                                          className={[
                                            commonClasses.btnSuccess,
                                            commonClasses.mr_1
                                          ].join(' ')}
                                          onClick={() => {
                                            onCopySlot(slot)
                                          }}
                                        >
                                          <FileCopyIcon />
                                        </IconButton>
                                        {deleteButton(slot)}
                                      </>
                                      )}
                                </TableCell>
                              </TableRow>
                            )
                          }
                        })
                      )
                    : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          {currentRegistry._id === undefined
                            ? (
                              <div className={commonClasses.plainText}>
                                No Item Found
                              </div>
                              )
                            : (
                              <LinearProgress />
                              )}
                        </TableCell>
                      </TableRow>
                      )}
                </TableBody>
              </Table>
            </Grid>
          </CardContent>
          <CustomDialog
            open={openSlotDialog}
            setOpenDialog={toggleSlotDialogStatus}
            title={dialog.title}
            subtitle={dialog.subtitle}
            content={dialog.content}
            displayDialogAction={dialog.displayDialogAction}
            customAction={dialog.customAction}
            size={dialog.size}
          />
        </form>
      </Paper>
    </div>
  )
}

export default Slots