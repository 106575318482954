import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'
import { filterByValue } from '../utilities/helperFunctions'
import { UpdateRegistry } from './registrySlice'

const swal = withReactContent(Swal)

const initialState = {
  isNew: false,
  registry: {},
  slots: [],
  orgSlots: [],
  slot: {},
  newSlot: null,
  selectedItemId: null,
  isDeleting: false,
  adUnitDictionary: [],
  loading: false,
  toast: {
    isOpen: false,
    message: '',
    variant: 'info'
  },
  newSlotResource: {
    name: 'New Slot',
    slotTypeId: null,
    locationId: null,
    adUnitPath: null,
    isInheritAdUnitFromRegistry: false,
    hasInViewRefresh: false,
    inViewRefreshCount: 5,
    inViewRefreshInterval: 35,
    isResponsive: false,
    viewportMappings: [],
    adSizes: [],
    targetings: [],
    isFluid: false
  },
  openSlotDialog: false,
  isChanged: false
}

const slotSlice = createSlice({
  name: 'registries',
  initialState,
  reducers: {
    SlotLoadingStatus: state => {
      state.loading = true
    },

    FetchRegistryById: (state, action) => {
      state.loading = false
      state.registry = action.payload
      if (state.registry.isSingleton) {
        state.slot = state.registry.slots[0]
      }
      state.slots = action.payload.slots
      state.orgSlots = action.payload.slots
    },

    CurrentSlot: (state, action) => {
      state.loading = false
      state.slot = action.payload
    },

    NewSlotResource: (state, action) => {
      state.newSlotResource.adUnitPath = null
      state.newSlotResource.adSizes = []
      state.newSlotResource.slotTypeId = null
      state.newSlotResource.locationId = null
      state.newSlotResource.hasInViewRefresh = action.payload.hasInViewRefresh
      state.newSlotResource.inViewRefreshCount = action.payload.inViewRefreshCount
      state.newSlotResource.inViewRefreshInterval = action.payload.inViewRefreshInterval
      state.newSlotResource.safeFrameConfig = action.payload.safeFrameConfig
      state.newSlotResource.safeFrameAllowOverlayExpansion = action.payload.safeFrameAllowOverlayExpansion
      state.newSlotResource.safeFrameAllowPushExpansion = action.payload.safeFrameAllowPushExpansion
      state.newSlotResource.safeFrameSandboxMode = action.payload.safeFrameSandboxMode
      state.newSlotResource.isResponsive = action.payload.isResponsive
      state.newSlotResource.isFluid = false
      prepareSlot(action.payload.site, state)
    },

    CloneSlotResource: (state, action) => {
      const slot = action.payload.slot
      state.newSlotResource.adUnitPath = null
      state.newSlotResource.adSizes = []
      state.newSlotResource.slotTypeId = null
      state.newSlotResource.locationId = null
      state.newSlotResource.isResponsive = slot.isResponsive
      state.newSlotResource.isFluid = slot.isFluid
      state.newSlotResource.hasInViewRefresh = slot.hasInViewRefresh
      state.newSlotResource.inViewRefreshCount = slot.inViewRefreshCount
      state.newSlotResource.inViewRefreshInterval = slot.inViewRefreshInterval
      state.newSlotResource.name = 'New Slot'
      state.newSlotResource.slotTypeId = slot.slotTypeId
      state.newSlotResource.locationId = slot.locationId
      state.newSlotResource.adUnitPath = slot.adUnitPath
      state.newSlotResource.isInheritAdUnitFromRegistry =
        slot.isInheritAdUnitFromRegistry
      state.newSlotResource.targetings = slot.targetings.slice()
      state.newSlotResource.viewportMappings = slot.viewportMappings.slice()
      state.newSlotResource.safeFrameConfig = slot.safeFrameConfig
      state.newSlotResource.safeFrameAllowOverlayExpansion =
        slot.safeFrameAllowOverlayExpansion
      state.newSlotResource.safeFrameAllowPushExpansion =
        slot.safeFrameAllowPushExpansion
      state.newSlotResource.safeFrameSandboxMode = slot.safeFrameSandboxMode
      state.newSlotResource.adSizes = slot.adSizes.slice()

      prepareSlot(action.payload.site, state)
      state.loading = false
    },

    AddRegistrySlot: (state, action) => {
      state.registry = action.payload
      state.slots = action.payload.slots
      state.orgSlots = action.payload.slots
      state.loading = false
      state.isNew = false
      swal.fire({
        title: 'Slot Added!',
        text: 'Slot added successfully.',
        icon: 'success'
      })
    },

    AddSlotToNewRegistry: (state, action) => {      
      state.registry = action.payload
      state.slots = action.payload.slots
      state.orgSlots = action.payload.slots
      state.loading = false
      state.isNew = false
    },

    UpdateRegistrySlot: (state, action) => {
      state.registry = action.payload
      state.slots = action.payload.slots
      state.orgSlots = action.payload.slots
      if (state.registry.isSingleton) {
        state.slot = state.registry.slots[0]
      }
      state.loading = false
      state.openSlotDialog = false
      swal.fire({
        title: 'Slot Updated!',
        text: 'Slot updated successfully.',
        icon: 'success'
      })
    },

    UpdateCurrentRegistry: (state, action) => {
      state.registry = action.payload
      state.slots = action.payload.slots
      state.orgSlots = action.payload.slots
      if (state.registry.isSingleton) {
        state.slot = state.registry.slots[0]
      }
      state.loading = false
    },

    DeleteRegistrySlot: (state, action) => {
      state.registry = action.payload
      state.slots = action.payload.slots
      state.orgSlots = action.payload.slots
      state.isDeleting = false
      swal.fire({
        title: 'Slot Deleted!',
        text: `${action.payload.name} deleted successfully.`,
        icon: 'success'
      })
    },

    DeployedRegistries: (state, action) => {
      state.deployedRegistries.push(action.payload)
    },

    FilterRegistrySlots: (state, action) => {
      const results = filterByValue(state.orgSlots, action.payload)
      state.loading = false
      state.slots = results
    },

    SelectedItemId: (state, action) => {
      state.selectedItemId = action.payload
    },

    ToggleDeletingingStatus: state => {
      state.isDeleting = !state.isDeleting
    },

    ToggleIsNewStatus: state => {
      state.isNew = !state.isNew
    },

    ToggleIsChange: (state, action) => {
      state.isChanged = action.payload
    },

    ToggleSlotDialogStatus: (state, action) => {
      if (action.payload === undefined) {
        state.openSlotDialog = !state.openSlotDialog
      } else {
        state.openSlotDialog = action.payload
      }
    },

    FetchAllAdUnits: (state, action) => {
      state.loading = false
      const adUnits = action.payload.topLevelAdUnits
      state.adUnits = action.payload.topLevelAdUnits
      const adUnitDictionary = []
      processLevel(adUnits, adUnitDictionary)
      state.adUnitDictionary = adUnitDictionary
    },

    FilterRegistrySlot: (state, action) => {
      const results = filterByValue(state.orgRegistries, action.payload)
      state.loading = false
      state.registries = results
    },

    SlotError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    },

    OpenToast: (state, action) => {
      state.toast = {
        isOpen: true,
        message: action.payload.message,
        variant: action.payload.variant
      }
    },

    CloseToast: state => {
      state.toast = {
        isOpen: false,
        message: '',
        variant: 'info'
      }
    },
    resetSlots: state => {
      state.slots = []
      state.orgSlots = []
    }
  }
})

export default slotSlice.reducer
export const {
  SlotLoadingStatus,
  FetchRegistryById,
  CurrentSlot,
  AddRegistrySlot,
  AddSlotToNewRegistry,
  CloneSlotResource,
  SelectedItemId,
  UpdateCurrentRegistry,
  UpdateRegistrySlot,
  DeleteRegistrySlot,
  DeployingStatus,
  ToggleDeletingingStatus,
  FetchAllAdUnits,
  FilterRegistrySlots,
  NewSlotResource,
  ToggleIsNewStatus,
  ToggleSlotDialogStatus,
  SlotError,
  OpenToast,
  CloseToast,
  resetSlots,
  ToggleIsChange
} = slotSlice.actions

const processLevel = (adUnits, adUnitDictionary) => {
  adUnits.forEach(adUnit => {
    adUnitDictionary[adUnit.dfpId] = adUnit
    if (adUnit.children) {
      processLevel(adUnit.children, adUnitDictionary)
    }
  })
}

const prepareSlot = (site, state) => {
  const rootAdUnit = state.adUnitDictionary[site.rootAdUnitId]
  if (state.newSlotResource.adUnitPath === null && rootAdUnit) {
    state.newSlotResource.adUnitPath = rootAdUnit.name
    const newAdUnit = state.adUnitDictionary[rootAdUnit._id]
    state.newSlotResource.adSizes = newAdUnit.adUnitSizes.slice()
  }
}

export const setSlotName = (registry, slot, slotTypes, locations) => {
  // this is only called when the name needs to change
  // ie: user changes registry.isSingleton or slot's SlotType/Location for non-singleton
  // build slot prefix (ad_mod_ for singletons, ad_<slotType>_<location>_)
  let slotNamePrefix = 'ad_mod_'
  if (!registry.isSingleton) {
    let slotTypeName = ''
    let locationName = ''
    if (slot.slotTypeId && slot.slotTypeId !== '') {
      const slotType = slotTypes.find(
        slotType => slotType._id === slot.slotTypeId
      )
      slotTypeName = '_' + slotType.name
      if (slot.locationId) {
        // sometimes the locationId isn't updated yet, so ensure the location is valid for the slot type
        const rsltLocations = []
        slotType.validLocationIds.forEach(validLocationId => {
          const location = locations.find(
            location => location._id === validLocationId
          )
          if (location !== undefined) {
            rsltLocations.push(location)
          }
        })
        if (rsltLocations.length > 0) {
          const location = rsltLocations.find(
            location => location._id === slot.locationId
          )
          if (location) {
            locationName = '_' + location.name
          }
        }
      }
    }
    slotNamePrefix = 'ad' + slotTypeName + locationName + '_'
  }

  // if slot's current name starts with the prefix (ie. ad_bnr_atf), do not change it
  if (!slot.name.includes(slotNamePrefix)) {
    let newSlotName = ''

    if (registry.isSingleton) {
      // set name to ad_mod_<9-digit-random-hex>
      newSlotName = slotNamePrefix
      for (let i = 0; i < 9; i++) {
        newSlotName += Math.floor(Math.random() * 16).toString(16)
      }
    } else {
      // build unique slot name: (ie. ad_bnr_atf_02)
      // set it to the first available (non-used) slotname by incrementing counter
      let counter = 1
      do {
        newSlotName = slotNamePrefix + (counter < 10 ? '0' : '') + counter
        if (!registry.slots?.find(slot => slot.name === newSlotName)) {
          break
        }
        counter++
      } while (true)
    }
    return newSlotName
  } else {
    return slot.name
  }
}

// #region Slot

export const cloneSlotResource = slot => {
  return async dispatch => {
    dispatch(SlotLoadingStatus())
    dispatch(CloneSlotResource(slot))
  }
}

export const addRegistrySlot = registry => {
  return async dispatch => {
    dispatch(SlotLoadingStatus())
    console.log('[slotSlice] ========== addRegistrySlot::Posting to API: ', registry)
    return request(`/registries/${registry._id}`, 'POST', registry)
      .then(res => {
        console.log('[slotSlice] ========== addRegistrySlot::Dispatching AddRegistrySlot and UpdateRegistry: ', res.data)
        dispatch(AddRegistrySlot(res.data))
        dispatch(UpdateRegistry(res.data))
      })
      .catch(err => dispatch(SlotError(err)))
  }
}

export const addNewRegistrySlot = registry => {
  return async dispatch => {
    console.log('[slotSlice] ========== addNewRegistrySlot::Dispatching AddSlotToNewRegistry: ', registry)
    dispatch(SlotLoadingStatus())
    dispatch(AddSlotToNewRegistry(registry))
  }
}

export const addSlotToNewRegistry = registry => {
  return async dispatch => {
    console.log('[slotSlice] ========== addSlotToNewRegistry::Dispatching AddSlotToNewRegistry and UpdateRegistry: ', registry)
    dispatch(SlotLoadingStatus())
    dispatch(AddSlotToNewRegistry(registry))
    dispatch(UpdateRegistry(registry))
  }
}

export const updateNewRegistrySlot = registry => {
  return async dispatch => {
    console.log('[slotSlice] ========== updateNewRegistrySlot::Dispatching UpdateRegistrySlot and UpdateRegistry: ', registry)
    dispatch(UpdateRegistrySlot(registry))
    dispatch(UpdateRegistry(registry))
  }
}

export const updateRegistrySlot = registry => {
  return async dispatch => {
    dispatch(SlotLoadingStatus())
    console.log('[slotSlice] ========== updateRegistrySlot::Posting to API: ', registry)
    return request(`/registries/${registry._id}`, 'POST', registry)
      .then(res => {
        console.log('[slotSlice] ========== updateRegistrySlot::Dispatching UpdateRegistrySlot and UpdateRegistry: ', res.data)
        dispatch(UpdateRegistrySlot(res.data))
        dispatch(UpdateRegistry(res.data))
      })
      .catch(err => dispatch(SlotError(err)))
  }
}

export const deleteSlotFromNewRegistry = registry => {
  return async dispatch => {
    dispatch(SlotLoadingStatus())
    dispatch(DeleteRegistrySlot(registry))
    dispatch(UpdateRegistry(registry))
  }
}

export const deleteRegistrySlot = registry => {
  return async dispatch => {
    dispatch(ToggleDeletingingStatus())
    return request(`/registries/${registry._id}`, 'POST', registry)
      .then(res => {
        dispatch(DeleteRegistrySlot(res.data))
        dispatch(UpdateRegistry(res.data))
      })
      .catch(err => dispatch(SlotError(err)))
  }
}

export const filterRegistrySlots = searchTerm => {
  return async dispatch => {
    dispatch(SlotLoadingStatus())
    dispatch(FilterRegistrySlots(searchTerm))
  }
}

// #endregion

// #region  AdUnits
export const getAllAdUnits = () => {
  return async dispatch => {
    dispatch(SlotLoadingStatus())
    return request('/dfp/adUnits')
      .then(res => {
        dispatch(FetchAllAdUnits(res.data))
      })
      .catch(err => dispatch(SlotError(err)))
  }
}

export const clearSlots = () => {
  return async dispatch => {
    dispatch(resetSlots())
  }
}

// #endregion
